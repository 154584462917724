import React, { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CheckoutItem from "../components/checkout/CheckoutItem";
import CheckoutItemLoading from "../components/checkout/CheckoutItemLoading";
import ImageLoading from "../components/ImageLoading";
import "../css/Checkout/checkoutForm.css";
const CartTotal = lazy(() => import("../components/checkout/CartTotal"));
const CheckoutPage = () => {
  const { t } = useTranslation();
  const cartState = useSelector((state) => state.cart.getCart);

  return (
    <>
      <div className="custom-breadcrumb">
        <div className="container">
          <ol className="breadcrumb ">
            <li className="breadcrumb-item">
              <Link to="/">{t("home")}</Link>
            </li>
            {document.body.dir === "rtl" && (
              <span className="breadcrumb-separator">/</span>
            )}
            <li className="breadcrumb-item active">{t("shopping_cart")}</li>
          </ol>
        </div>
      </div>
      <section className="checkout-section">
        <div className="container">
          <div className="row">
            <div className="col-12 top-header">
              <h3>{t("shopping_cart")}</h3>
            </div>
          </div>
          <div className="row">
            {cartState && (
              <div className="col-lg-9">
                <div className="checkout-products-incart mb-3">
                  <ul className="list-unstyled">
                    {cartState.loading ? (
                      [...Array(2)].map((item, index) => (
                        <CheckoutItemLoading key={index} />
                      ))
                    ) : cartState.items && cartState.items.length === 0 ? (
                      <div className="col-md-12 text-center">
                        <i
                          className="fas fa-exclamation-triangle fa-7x"
                          style={{ marginBottom: "15px" }}
                        ></i>
                        <h1>{t("cart_items_count_zero")}</h1>
                      </div>
                    ) : (
                      cartState.items.map((product) => (
                        <CheckoutItem product={product} key={product.slug} />
                      ))
                    )}
                  </ul>
                </div>
                <Link to="/shop">
                  <button className="submit-btn" aria-label="Continue shopping">
                    {document.body.dir === "ltr" ? (
                      <i className="fa fa-chevron-left mr-2"></i>
                    ) : (
                      <i className="fa fa-chevron-right ml-2"></i>
                    )}
                    {t("continue_shopping")}
                  </button>
                </Link>
              </div>
            )}

            <Suspense
              fallback={<ImageLoading width={"100%"} height={"200px"} />}
            >
              <CartTotal />
            </Suspense>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckoutPage;
