import fetchBestSellingProductsAction from "./bestSellingProductsAction";
import fetchFeaturedProductsAction from "./featuredProductsActions";
import fetchNewProductsAction from "./newProductsActions";
import fetchProductDetails from "./productDetailsActions";
import fetchShopAction from "./shopAction";
import fetchTopRatedProductsAction from "./topRatedProductsActions";
import fetchRelatedProductsAction from "./relatedProductsAction";
import fetchProductRatingsActions from "./ratings/productRatingsActions";
const productsActions = {
  fetchBestSellingProductsAction,
  fetchFeaturedProductsAction,
  fetchNewProductsAction,
  fetchTopRatedProductsAction,
  fetchShopAction,
  fetchProductDetails,
  fetchRelatedProductsAction,
  fetchProductRatingsActions
};

export default productsActions;
