import {
  ABOUT_US_REQUEST,
  ABOUT_US_SUCCESS,
  ABOUT_US_FAIL,
} from "../../../shared/constants";

import { api } from "../../../shared/shared";

const fetchAboutUsBegin = () => ({
  type: ABOUT_US_REQUEST,
});

const fetchAboutUsSuccess = (about_us) => ({
  type: ABOUT_US_SUCCESS,
  payload: { about_us },
});

const fetchAboutUsFailure = (error) => ({
  type: ABOUT_US_FAIL,
  payload: { error },
});
const fetchAboutUsAction = () => (dispatch) => {
  dispatch(fetchAboutUsBegin());
  api
    .get("/api/about-us")
    .then((res) => {
      if (res.data.success) {
        dispatch(fetchAboutUsSuccess(res.data.data));
      } else {
        dispatch(fetchAboutUsFailure(res.data.message));
      }
    })
    .catch((error) => {
      dispatch(fetchAboutUsFailure(error.message));
    });
};
export default fetchAboutUsAction;
