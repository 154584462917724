import {
  WILAYAS_REQUEST,
  WILAYAS_SUCCESS,
  WILAYAS_FAIL,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";

const fetchWilayasBegin = () => ({
  type: WILAYAS_REQUEST,
});

const fetchWilayasSuccess = (wilayas) => ({
  type: WILAYAS_SUCCESS,
  payload: { wilayas },
});

const fetchWilayasFailure = (error = null) => ({
  type: WILAYAS_FAIL,
  payload: { error },
});

const fetchWilayasAction = () => (dispatch) => {
  dispatch(fetchWilayasBegin());
  api
    .get("/api/wilayas")
    .then((res) => {
      if (res.data.success) dispatch(fetchWilayasSuccess(res.data.data));
      else dispatch(fetchWilayasFailure(res.data.message));
    })
    .catch((error) => {
      return dispatch(fetchWilayasFailure(error.message));
    });
};

export default fetchWilayasAction;
