import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { fetchRemoveItemFromCart } from "../../redux/actions/cart/removeItemActions";
import { fetchUpdateQuantityCartItem } from "../../redux/actions/cart/updateQuantityItemActions";
import { number_format } from "../../shared/shared";
import WishlistItemImage from "../wishlist/WishlistItemImage";
import useWholesale from "../../hooks/useWholesale";
import WholesaleSelect from "../products/WholesaleSelect";

const CheckoutItem = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const settingsState = useSelector((state) => state.storeInfos);
  const featuresState = useSelector((state) => state.features);

  const [quantity, setQuantity] = useState(props.product.qty);
  const { getWhsalePrice } = useWholesale(props.product);

  const maxQuantity = props.product.stock ?? 10;
  const minQuantity = props.product?.order_min_quantity ?? 1 ;

  const handleQtyChange = useCallback(
    (e) => {
      let { value, min, max } = e.target;
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));
      setQuantity(value);
      debouncedQtyChange()
    },
    [minQuantity, maxQuantity]
  );

  const debouncedQtyChange = useDebouncedCallback(
    () => dispatch(fetchUpdateQuantityCartItem(props.product.rowId, quantity)),
    2000
  );

  const handleIncrementQty = () => {
    if (quantity < maxQuantity) {
      setQuantity(quantity + 1);
      debouncedIncrementQty();
    }
  };

  const debouncedIncrementQty = useDebouncedCallback(
    () => dispatch(fetchUpdateQuantityCartItem(props.product.rowId, quantity)),
    2000
  );

  const handleDecrementQty = () => {
    if (quantity > minQuantity) {
      setQuantity(quantity - 1);
      debouncedDecrementQty();
    }
  };
  const debouncedDecrementQty = useDebouncedCallback(
    () => dispatch(fetchUpdateQuantityCartItem(props.product.rowId, quantity)),
    2000
  );

  const handleDeleteItem = (event) => {
    event.preventDefault();
    dispatch(fetchRemoveItemFromCart(props.product.rowId));
  };

  return (
    <li
      className="checkout-products-item row align-items-center"
      key={props.key}
    >
      <div className="col-md-3 col-6 col-sm-6 mb-3">
        <div className="checkout-products-item-img">
          <WishlistItemImage image={props.product.img} />
        </div>
        {featuresState?.features?.includes("wholesale") &&
          props.product?.wholesale?.length > 0 && (
            <WholesaleSelect wh_offers={props.product?.wholesale} />
          )}
      </div>
      <div className="col-md-3 col-6 col-sm-6 mb-3">
        <p className="checkout-products-item-name">
          <Link to={"/shop/" + props.product.slug}>{props.product.name}</Link>
        </p>
        <span className="checkout-products-item-price">
          {settingsState?.store_infos?.currency
            ? settingsState.store_infos.currency !== "DZD"
              ? t("currency", {
                  price: number_format(
                    getWhsalePrice(quantity, props.product.price)
                  ),
                  currency: settingsState.store_infos.currency,
                })
              : t("DA", {
                  price: number_format(
                    getWhsalePrice(quantity, props.product.price)
                  ),
                })
            : number_format(getWhsalePrice(quantity, props.product.price))}
        </span>
        <ul className="list-unstyled checkout-products-item-properties mt-3">
          {Object.keys(props.product.options ?? {}).map((option, index) => (
            <li key={option + index}>
              {option}: <span>{props.product.options[option]}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="col-md-4 col-6 col-sm-6 mb-3">
        <div className="d-flex w-100 align-items-center justify-content-around">
          <div className="quantity-selector mx-2">
            <input
              type="number"
              min={minQuantity}
              max={maxQuantity}
              value={quantity}
              onChange={handleQtyChange}
            />
            <div className="d-inline-block">
              <button
                type="button"
                className="plus-btn"
                aria-label="Increment quantity"
                onClick={handleIncrementQty}
              >
                <i className="fa fa-plus" />
              </button>
              <button
                type="button"
                aria-label="Decrement quantity"
                onClick={handleDecrementQty}
              >
                <i className="fa fa-minus" />
              </button>
            </div>
          </div>
          <div className="mx-2">
            <span style={{ color: "#e23e57", fontWeight: 600 }}>
              {settingsState?.store_infos?.currency
                ? settingsState.store_infos.currency !== "DZD"
                  ? t("currency", {
                      price: number_format(
                        getWhsalePrice(quantity, props.product.price) * quantity
                      ),
                      currency: settingsState.store_infos.currency,
                    })
                  : t("DA", {
                      price: number_format(
                        getWhsalePrice(quantity, props.product.price) * quantity
                      ),
                    })
                : number_format(
                    getWhsalePrice(quantity, props.product.price) * quantity
                  )}
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-2 col-6 col-sm-6 mb-sm-0 mb-3">
        <span className="checkout-products-remove-item">
          <a href="/#" onClick={(event) => handleDeleteItem(event)}>
            <i className="fa fa-times" />
          </a>
        </span>
      </div>
    </li>
  );
};
export default CheckoutItem;
