import React from 'react'
import ImageLoading from '../ImageLoading'

const OrderProductLoading = (props) => {
  return (
    <li className="checkout-products-item row align-items-center" key={props.key} >
      <div className="col-md-3 col-6 col-sm-6 mb-3">
        <div className="checkout-products-item-img">
          <ImageLoading width={"100px"} aspectRatio={'100/127.5'} />
        </div>
      </div>
      <div className="col-md-5 col-6 col-sm-6 mb-3">
        <h5 className="checkout-products-item-name" style={{ marginBottom: '3px' }}>
          <a href='/#'>
            <ImageLoading width={"100px"} aspectRatio={'100/20'} />
          </a>
        </h5>
        <span className="checkout-products-item-price"><ImageLoading width={"100px"} aspectRatio={'100/20'} /></span>
      </div>
      <div className="col-md-4 col-6 col-sm-6 mb-3">
        <div className="d-flex w-100 align-items-center">
          <span><ImageLoading width={"21px"} aspectRatio={'1'} /></span>
          <div 
            className={`${document.body.dir === "ltr" ? "ml-auto" : ""}`}
            style={{
              marginInlineStart: document.body.dir === "rtl" && "auto"
            }}
          >
            <span style={{ color: '#e23e57', fontWeight: 600 }}><ImageLoading width={"63.56px"} aspectRatio={'63.56/19'} /></span>
          </div>
        </div>
      </div>
    </li>
  )
}

export default OrderProductLoading