import React from "react";
import ProductsCarousel from "../products/ProductsCarousel";

const UniversalSection = (props) => {
  return (
    <section className="new-products-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="top-rated-section-header">
              <h3>{props.title}</h3>
            </div>
            <div className="new-products-wrapper">
              <div className="row">
                <ProductsCarousel
                  className="new-products-slider"
                  componentKey={`${props.carouselKey}-products-slider`}
                  products={props.products}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UniversalSection;
