import "./App.css";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { lazy, Suspense, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import fetchFeatures from "./redux/actions/featuresAction";
import fetchCsrfToken from "./redux/actions/csrfTokenAction";
import { fetchGetCart } from "./redux/actions/cart/getCartActions";
import { fetchUserAction } from "./redux/actions/auth/userActions";
import fetchWishlist from "./redux/actions/wishlist/wishlistActions";
import fetchStoreInfos from "./redux/actions/settings/storeInfosActions";
import { fetchFeaturedCategoriesAction } from "./redux/actions/categories/featuredCategoriesActions";

import AppLayout from "./layout/AppLayout";
import ShopPage from "./pages/ShopPage";
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import CheckoutPage from "./pages/CheckoutPage";
import ConfirmOrderPage from "./pages/ConfirmOrderPage";

const TermsAndPoliciesPage = lazy(() => import("./pages/TermsAndPoliciesPage"));
const AboutUsPage = lazy(() => import("./pages/AboutUsPage"));
const ContactUsPage = lazy(() => import("./pages/ContactUsPage"));
const ForgotPasswordPage = lazy(() => import("./pages/ForgotPasswordPage"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPasswordPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const NotFoundPage = lazy(() => import("./pages/404Page"));
const RegisterPage = lazy(() => import("./pages/RegisterPage"));
const OrdersPage = lazy(() => import("./pages/orders/OrdersPage"));
const OrderDetailsPage = lazy(() => import("./pages/orders/OrderDetailsPage"));
const WishlistPage = lazy(() => import("./pages/WishlistPage"));

function App() {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.authenticated);
  const settingsState = useSelector((state) => state.storeInfos);
  const featuresState = useSelector((state) => state.features);
  const { i18n } = useTranslation();
  const isMounted = useRef(false);

  useEffect(() => {
    dispatch(fetchCsrfToken());
    dispatch(fetchStoreInfos());
    dispatch(fetchFeatures());
    dispatch(fetchFeaturedCategoriesAction());
    dispatch(fetchUserAction());
  }, []);

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  useEffect(() => {
    if (featuresState?.features?.includes("wishlist"))
      dispatch(fetchWishlist());
  }, [featuresState?.features?.includes("wishlist")]);

  useEffect(() => {
    if (featuresState?.features?.includes("add-to-cart"))
      dispatch(fetchGetCart());
  }, [featuresState?.features?.includes("add-to-cart")]);

  useEffect(() => {
    if (isMounted.current) {
      if (settingsState?.store_infos != null) {
        document.body.dataset.theme = settingsState.store_infos.theme;
        document.body.dataset.color = settingsState.store_infos.color;
      }
    } else isMounted.current = true;
  }, [settingsState?.store_infos]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="shop/:productSlug" element={<ProductPage />} />
          <Route path="/categories/:categorySlug" element={<ShopPage />} />

          <Route element={<RequireGuest authState={authState} />}>
            <Route path="/login" element={<Suspense fallback={<></>}><LoginPage /></Suspense>} />
            <Route path="/register" element={<Suspense fallback={<></>}><RegisterPage /></Suspense>} />
            <Route path="/forgot-password" element={<Suspense fallback={<></>}><ForgotPasswordPage /></Suspense>} />
            <Route path="/reset-password" element={<Suspense fallback={<></>}><ResetPasswordPage /></Suspense>} />
          </Route>

          <Route element={<RequireAuth authState={authState} />}>
            <Route path="/orders" element={<Suspense fallback={<></>}><OrdersPage /></Suspense>} />
            <Route path="/orders/:orderId" element={<Suspense fallback={<></>}><OrderDetailsPage /></Suspense>} />
            {featuresState?.features?.includes("wishlist") && (
              <Route path="/wishlist" element={<Suspense fallback={<></>}><WishlistPage /></Suspense>} />
            )}
          </Route>

          <Route
            path="/terms-and-policies"
            element={<Suspense fallback={<></>}><TermsAndPoliciesPage /></Suspense>}
          />
          <Route path="/about-us" element={<Suspense fallback={<></>}><AboutUsPage /></Suspense>} />
          {featuresState?.features?.includes("contact") && (
            <Route path="/contact-us" element={<Suspense fallback={<></>}><ContactUsPage /></Suspense>} />
          )}

          {featuresState?.features?.includes("add-to-cart") && (
            <>
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="/confirm-order" element={<ConfirmOrderPage />} />
            </>
          )}

          <Route path="*" element={<Suspense fallback={<></>}><NotFoundPage /></Suspense>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const RequireAuth = ({ authState }) => {
  const location = useLocation();
  return authState.isLogged ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

const RequireGuest = ({ authState }) => {
  const location = useLocation();
  if (authState.isLogged) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return <Outlet />;
};

export default App;
