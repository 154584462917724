import {
  FILTERS_REQUEST,
  FILTERS_SUCCESS,
  FILTERS_FAIL,
} from "../../shared/constants";
import { api } from "../../shared/shared";

const fetchFiltersBegin = () => ({
  type: FILTERS_REQUEST,
});

const fetchFiltersSuccess = (filters) => ({
  type: FILTERS_SUCCESS,
  payload: { filters },
});

const fetchFiltersFailure = (message) => ({
  type: FILTERS_FAIL,
  payload: { message },
});

export const fetchFiltersAction = () => (dispatch) => {
  dispatch(fetchFiltersBegin());
  api
    .get("api/filters")
    .then((res) => {
      if (res.data.success) {
        dispatch(fetchFiltersSuccess(res.data.data));
      } else {
        dispatch(fetchFiltersFailure(res.data.message));
      }
    })
    .catch((error) => {
      dispatch(
        fetchFiltersFailure(
          error.response.data.message
        )
      );
    });
}