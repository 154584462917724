import React from "react";
import ImageLoading from "../ImageLoading";

const CartItemLoading = () => {
  return (
    <div className="cart-item d-flex">
      <div className="cart-item-img-wrapper">
        <ImageLoading width={"70px"} aspectRatio={"70/89.25"} />
      </div>
      <div className="cart-item-details">
        <div className="cart-item-product-name">
          <div style={{ marginBottom: "3px" }}>
            <ImageLoading width={"88px"} aspectRatio={"88/20"} />
          </div>
        </div>
        <div className="cart-item-product-price">
          <ImageLoading width={"88px"} aspectRatio={"88/20"} />
        </div>
      </div>
    </div>
  );
};

export default CartItemLoading;
