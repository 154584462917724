import React, { useState } from "react";
import ImageLoading from "../ImageLoading";

const CartItemImage = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <img
        srcSet={`${props.image}?w=70&h=90 1440w,${props.image}?w=70&h=90 1024w,${props.image}?w=70&h=90 768w,${props.image}?w=70&h=90 425w,`}
        sizes="
              (max-width: 425px) 70px,
              (max-width: 768px) 70px,
              (max-width: 1024px) 70px,
              70px"
        onLoad={() => setLoaded(true)}
        style={{ display: "none" }}
        alt={props.image}
      />
      {loaded ? (
        <img
          srcSet={`${props.image}?w=70&h=90 1440w,${props.image}?w=70&h=90 1024w,${props.image}?w=70&h=90 768w,${props.image}?w=70&h=90 425w,`}
          sizes="
            (max-width: 425px) 70px,
            (max-width: 768px) 70px,
            (max-width: 1024px) 70px,
            70px"
          alt={props.image}
        />
      ) : (
        <ImageLoading width={"70px"} height={"90px"} />
      )}
    </>
  );
};

export default CartItemImage;
