import {
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";

const fetchProductDetailsBegin = () => ({
  type: PRODUCT_DETAILS_REQUEST,
});

const fetchProductDetailsSuccess = (product) => ({
  type: PRODUCT_DETAILS_SUCCESS,
  payload: { product },
});

const fetchProductDetailsFailure = (error) => ({
  type: PRODUCT_DETAILS_FAIL,
  payload: { error },
});

const fetchProductDetails = (slug) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchProductDetailsBegin());
    api
      .get("api/products/" + slug)
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchProductDetailsSuccess(res.data.data));
          resolve(res.data.data);
        } else {
          dispatch(fetchProductDetailsFailure(res.data.message));
          reject(res.data.message);
        }
      })
      .catch((error) => {
        dispatch(fetchProductDetailsFailure(error.message));
        reject(error.message);
      });
  });
export default fetchProductDetails;
