
import { PRODUCT_RATINGS_FAIL, PRODUCT_RATINGS_REQUEST, PRODUCT_RATINGS_SUCCESS } from "../../../../shared/constants";
import { api } from "../../../../shared/shared";

const fetchProductRatingsBegin = () => ({
  type: PRODUCT_RATINGS_REQUEST,
});

const fetchProductRatingsSuccess = (data, meta, links) => ({
  type: PRODUCT_RATINGS_SUCCESS,
  payload: { data, meta, links },
});

const fetchProductRatingsFailure = (error) => ({
  type: PRODUCT_RATINGS_FAIL,
  payload: { error },
});

const fetchProductRatingsActions = (slug, page = 1) => (dispatch) => {
  dispatch(fetchProductRatingsBegin());
  let path = '';
  if (page > 1) {
    path = `?page=${page}`
  }
  api
    .get("api/products/" + slug + "/reviews" + path)
    .then((res) => {
      if (res.data.success) {
        dispatch(fetchProductRatingsSuccess(res.data.data, res.data.meta, res.data.links));
      } else {
        dispatch(fetchProductRatingsFailure(res.data.message));
      }
    })
    .catch((error) => {
      dispatch(fetchProductRatingsFailure(error.response.data));
    });

};
export default fetchProductRatingsActions;