import React from "react";
import { number_format } from "../../shared/shared";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../css/Shop/activeFilters.css";

const ActiveFilters = ({ filters, setFilters }) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const settingsState = useSelector((state) => state.storeInfos);

    const clearFilterProperties = (event, property, option) => {
        event.preventDefault();
        let temps = { ...filters };
        delete temps["properties"][property][option];
        setFilters(temps);
      };
    
      const clearFilterPrice = (event) => {
        event.preventDefault();
        searchParams.delete("min_price");
        searchParams.delete("max_price");
        setSearchParams(searchParams);
      };
    
      const initSearch = (event) => {
        event.preventDefault();
        searchParams.delete("search");
        setSearchParams(searchParams);
      };

  return (
    <div className="active-filters-wrapper d-flex align-items-center">
      <span className="active-filters-header">{t("activeFilters")} :</span>
      <ul
        className="list-unstyled mb-0 d-flex"
        style={{ paddingRight: document.body.dir === "rtl" && 0 }}
      >
        {searchParams.get("search") && (
          <li className="mx-2" key={`filters-search`}>
            {`${searchParams.get("search")}`}
            <a href="/#" onClick={initSearch}>
              <i className="fa fa-times ml-1" style={{ fontSize: 13 }} />
            </a>
          </li>
        )}
        {searchParams.get("max_price") && searchParams.get("min_price") && (
          <li
            className="mx-2"
            key={`filters-${searchParams.get("min_price")}-${searchParams.get(
              "max_price"
            )}`}
          >
            {settingsState?.store_infos?.currency ? (
              settingsState.store_infos.currency !== "DZD" ? (
                <>
                  {t("currency", {
                    price: number_format(searchParams.get("min_price")),
                    currency: settingsState.store_infos.currency,
                  })}
                  <span> - </span>
                  {t("currency", {
                    price: number_format(searchParams.get("max_price")),
                    currency: settingsState.store_infos.currency,
                  })}
                </>
              ) : (
                <>
                  {t("DA", {
                    price: number_format(searchParams.get("min_price")),
                  })}
                  <span> - </span>
                  {t("DA", {
                    price: number_format(searchParams.get("max_price")),
                  })}
                </>
              )
            ) : (
              <>
                {number_format(searchParams.get("min_price"))}
                <span> - </span>
                {number_format(searchParams.get("max_price"))}
              </>
            )}
            <a href="/#" onClick={clearFilterPrice}>
              <i className="fa fa-times ml-1" style={{ fontSize: 13 }} />
            </a>
          </li>
        )}
        {Object.keys(filters["properties"] ?? {}).map((key) =>
          Object.values(filters["properties"][key]).map((value) => (
            <li className="mx-2" key={`filters-${key}-${value}`}>
              {value}
              <a
                href="/#"
                onClick={(event) => clearFilterProperties(event, key, value)}
              >
                <i className="fa fa-times ml-1" style={{ fontSize: 13 }} />
              </a>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default ActiveFilters;
