import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../css/Product/wholesale.css";
import { number_format } from "../../shared/shared";
import { useSelector } from "react-redux";

function Wholesale({ whItems }) {
  const [showTable, setShowTable] = useState(false);
  const { t } = useTranslation();
  const settingsState = useSelector((state) => state.storeInfos);

  const renderPrice = useCallback(
    (currency, price) => {
      const formattedPrice = number_format(price);

      if (!currency) {
        return formattedPrice;
      }

      if (currency === "DZD") {
        return t("DA", { price: formattedPrice });
      }

      return t("currency", { price: formattedPrice, currency });
    },
    [settingsState]
  );

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  return (
    <>
      <div
        className="wholesale row justify-content-between px-3 py-2 align-items-center mb-3"
        onClick={toggleTable}
      >
        <label
          className={`mt-4 ${document.body.dir === "rtl" && "text-right"}`}
        >
          {t("Wholesale")}
        </label>
        <div className="mr-2 p-2">
          <i
            className={`fa ${showTable ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
      </div>
      {showTable && (
        <div>
          <dl className="wholesale-table-head d-flex">
            <dt>{t("price")}</dt>
            <dd className="flex-grow-1">
              <span>{t("quantity")}</span>
            </dd>
          </dl>
          {whItems?.wholesale?.map((offer, idx) => (
            <dl className="wholesale-table d-flex" key={idx}>
              <dt>
                {renderPrice(settingsState.store_infos.currency, offer?.price)}
              </dt>
              <dd className="flex-grow-1">
                {offer?.max_quantity === null ||
                offer?.end_quantity === null ? (
                  <>
                    {`${t("from")} `}
                    <span>{offer?.min_quantity || offer?.start_quantity}</span>
                    {` ${t("And More")} `}
                  </>
                ) : (
                  <>
                    {`${t("from")} `}
                    <span>{offer?.min_quantity || offer?.start_quantity}</span>
                    {` ${t("to")} `}
                    <span>{offer?.max_quantity || offer?.end_quantity}</span>
                  </>
                )}
              </dd>
            </dl>
          ))}
        </div>
      )}
    </>
  );
}

export default React.memo(Wholesale);
