import { CHECKOUT_PRODUCT_REQUEST, CHECKOUT_PRODUCT_SUCCESS, CHECKOUT_PRODUCT_FAIL } from '../../shared/constants';
import { api } from "../../shared/shared";

const fetchCheckoutProductBegin = () => ({
    type : CHECKOUT_PRODUCT_REQUEST
});

const fetchCheckoutProductSuccess = (message) => ({
    type : CHECKOUT_PRODUCT_SUCCESS,
    payload : { message }
});

const fetchCheckoutProductFailure = (statusCode = 200, message, errors = null) => ({
    type : CHECKOUT_PRODUCT_FAIL,
    payload : { statusCode, message, errors }
});

const fetchCheckoutProduct = (data) => (dispatch) => 
    new Promise((resolve, reject) => {
        dispatch(fetchCheckoutProductBegin());
        api.post("api/checkout-product", data)
            .then((res) => {
                if(res.data.success) {
                    dispatch(fetchCheckoutProductSuccess(res.data.message));
                    /* if(res.data.chargily_link !== null)
                        window.location.replace(res.data.chargily_link) */
                    resolve(res.data.message);
                } else {
                    dispatch(fetchCheckoutProductFailure(200, res.data.message));
                    reject(res.data.message);
                }
            })
            .catch((errors) => {
                dispatch(fetchCheckoutProductFailure(
                    errors.response.status,
                    errors.response.data.message,
                    errors.response.data.errors
                ));
                reject(errors.response.data.message);
            });
});
export default fetchCheckoutProduct;