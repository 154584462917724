import {
  TOP_RATED_PRODUCTS_REQUEST,
  TOP_RATED_PRODUCTS_SUCCESS,
  TOP_RATED_PRODUCTS_FAIL,
} from "../../../shared/constants";

import { api } from "../../../shared/shared";

const fetchTopRatedProductsBegin = () => ({
  type: TOP_RATED_PRODUCTS_REQUEST,
});

const fetchTopRatedProductsSuccess = (products) => ({
  type: TOP_RATED_PRODUCTS_SUCCESS,
  payload: { products },
});

const fetchTopRatedProductsFailure = (error) => ({
  type: TOP_RATED_PRODUCTS_FAIL,
  payload: { error },
});

const fetchTopRatedProductsAction = () => (dispatch) => {
  dispatch(fetchTopRatedProductsBegin());
  api
    .get("/api/products/top-rated")
    .then((res) =>
      res.data.success
        ? dispatch(fetchTopRatedProductsSuccess(res.data.data))
        : dispatch(fetchTopRatedProductsFailure(res.data.message))
    )
    .catch((error) => dispatch(fetchTopRatedProductsFailure(error.message)));
};

export default fetchTopRatedProductsAction;
