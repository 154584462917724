import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { number_format } from "../../shared/shared";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import "../../css/Shop/filters.css";

const DesktopFilters = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const featuresState = useSelector((state) => state.features);
  const filtersState = useSelector((state) => state.filters);
  const settingsState = useSelector((state) => state.storeInfos);

  const removeDuplicateProperties = () => {
    if (
      filtersState?.filters?.length !== 0 &&
      filtersState?.filters["options"].length !== 0
    )
      for (const [key, value] of Object.entries(
        filtersState.filters["options"]
      ))
        filtersState.filters["options"][key] = Array.from(new Set(value));

    return filtersState;
  };

  const getPriceRanges = (min, max) => {
    let part = (max - min) / 4;
    return [
      { min, max: min + part },
      { min: min + part, max: min + part * 2 },
      { min: min + part * 2, max: min + part * 3 },
      { min: min + part * 3, max },
    ];
  };

  const handlePrice = (value) => {
    let price = value.replace(/\s/g, "");
    searchParams.set("min_price", price.split("-")[0]);
    searchParams.set("max_price", price.split("-")[1]);
    setSearchParams(searchParams);
  };

  const handleFilterProperties = (event) => {
    let filter = JSON.parse(event.target.name);
    let temps = { ...props.filters };
    if (event.target.checked) {
      if (temps["properties"] === undefined) temps["properties"] = [];
      if (temps["properties"][filter.property] === undefined)
        temps["properties"][filter.property] = [];
      temps["properties"][filter.property][filter.option] = filter.option;
    } else delete temps["properties"][filter.property][filter.option];
    props.setFilters(temps);
  };

  const getValideSearch = (search) => {
    let temps = "" + search;
    return temps.replace(/(page=\d+)|(&page=\d+)/i, "");
  };

  return (
    <div className="filters-wrapper d-none d-lg-block">
      <div className="filters-header">
        <h3>{t("filter")}</h3>
      </div>
      <div className="filters-container">
        {featuresState?.features?.includes("categories") &&
          filtersState.filters["categories"] &&
          filtersState.filters["categories"].length > 0 && (
            <div className="price-filter-wrapper">
              <h5 style={{ textAlign: document.body.dir === "rtl" && "start" }}>
                {t("products_categories")}
              </h5>
              <ul
                className="shop-categories list-unstyled"
                style={{ paddingRight: document.body.dir === "rtl" && "0px" }}
              >
                {filtersState.filters["categories"].map((category) =>
                  category.descendants.length > 0 ? (
                    <li
                      key={`categories-1-${category.slug}-${category.id}`}
                      style={{
                        textAlign: document.body.dir === "rtl" && "right",
                      }}
                    >
                      <span
                        className="subcategories-toggler"
                        data-toggle="collapse"
                        data-target={`#subcategory-${category.slug}-${category.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.detail === 1
                            ? (props.click.current = [1])
                            : (props.click.current = [
                                ...props.click.current,
                                e.detail,
                              ]);
                          props.click.current.length === 1 &&
                            props.setShow({
                              ...props.show,
                              [category.slug]: !props.show[category.slug],
                            });
                        }}
                      >
                        {props.show[category.slug] ? (
                          <i className="fa fa-minus" />
                        ) : (
                          <i className="fa fa-plus" />
                        )}
                      </span>
                      <Link
                        to={`/categories/${category.slug}${getValideSearch(
                          location.search
                        )}`}
                      >
                        {category.name}
                      </Link>
                      <ul
                        className="list-unstyled collapse subcategories"
                        id={`subcategory-${category.slug}-${category.id}`}
                        style={{
                          paddingRight: document.body.dir === "rtl" && "0px",
                        }}
                      >
                        {category.descendants.map((sub_category) => (
                          <li
                            key={`sub-categories-1-${sub_category.slug}-${sub_category.id}`}
                            style={{
                              textAlign: document.body.dir === "rtl" && "right",
                            }}
                          >
                            <Link
                              to={`/categories/${
                                sub_category.slug
                              }${getValideSearch(location.search)}`}
                            >
                              {sub_category.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    <li
                      key={`categories-1-${category.slug}-${category.id}`}
                      style={{
                        textAlign: document.body.dir === "rtl" && "right",
                      }}
                    >
                      <span>
                        <i className="fa fa-minus" />
                      </span>
                      <Link
                        to={`/categories/${category.slug}${getValideSearch(
                          location.search
                        )}`}
                      >
                        {category.name}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
        {filtersState.filters["min"] && filtersState.filters["max"] && (
          <div className="price-filter-wrapper">
            <h5 style={{ textAlign: document.body.dir === "rtl" && "start" }}>
              {t("price")}
            </h5>
            <ul
              className="price-filters-list list-unstyled"
              style={{ paddingRight: document.body.dir === "rtl" && "0px" }}
            >
              {getPriceRanges(
                filtersState.filters["min"],
                filtersState.filters["max"]
              ).map((range) => (
                <li key={`range-price-1-${range["min"]}-${range["max"]}`}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="price-filter-1"
                      value={`${range["min"]}-${range["max"]}`}
                      defaultChecked={
                        parseInt(searchParams.get("min_price") ?? "") ===
                          parseInt(range["min"]) &&
                        parseInt(searchParams.get("max_price") ?? "") ===
                          parseInt(range["max"])
                      }
                      onChange={(e) => handlePrice(e.target.value)}
                    />
                    <span>
                      {settingsState?.store_infos?.currency ? (
                        settingsState.store_infos.currency !== "DZD" ? (
                          <>
                            {t("currency", {
                              price: number_format(range["min"]),
                              currency: settingsState.store_infos.currency,
                            })}
                            <span> - </span>
                            {t("currency", {
                              price: number_format(range["max"]),
                              currency: settingsState.store_infos.currency,
                            })}
                          </>
                        ) : (
                          <>
                            {t("DA", { price: number_format(range["min"]) })}
                            <span> - </span>
                            {t("DA", { price: number_format(range["max"]) })}
                          </>
                        )
                      ) : (
                        <>
                          {number_format(range["min"])}
                          <span> - </span>
                          {number_format(range["max"])}
                        </>
                      )}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        )}
        {removeDuplicateProperties().filters["options"] &&
          Object.keys(removeDuplicateProperties().filters["options"]).length >
            0 &&
          Object.keys(removeDuplicateProperties().filters["options"]).map(
            (key, index) => (
              <div
                className="size-filter-wrapper"
                key={`side-1-${index}-property-${key}`}
              >
                <h5
                  style={{ textAlign: document.body.dir === "rtl" && "start" }}
                >
                  {t("filter_by", { filter: key })}
                </h5>
                <ul
                  className="size-filters-list list-unstyled"
                  style={{ paddingRight: document.body.dir === "rtl" && "0px" }}
                >
                  {removeDuplicateProperties().filters["options"][key].length >
                    0 &&
                    removeDuplicateProperties().filters["options"][key].map(
                      (option, index) => (
                        <li
                          key={`side-1-${index}-property-${key}-option-${option}`}
                        >
                          <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              name={`{"property":"${key}","option":"${option}"}`}
                              checked={
                                props.filters["properties"] &&
                                props.filters["properties"][key] &&
                                props.filters["properties"][key][option]
                              }
                              onChange={handleFilterProperties}
                            />
                            <span>{option}</span>
                          </label>
                        </li>
                      )
                    )}
                </ul>
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default DesktopFilters;
