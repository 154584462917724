import React from "react";

export default () => {
  let [modal, setModal] = React.useState(false);
  let [modalContent, setModalContent] = React.useState("");

  let handleModal = (content = false) => {
    setModal(!modal);
    if (content) {
      setModalContent(content);
      document.body.classList.add("modal-open");
      document.body.style.paddingRight = "17px";
    } else {
      document.body.classList.remove("modal-open");
      document.body.removeAttribute('style')
    }
  };

  return { modal, handleModal, modalContent };
};
