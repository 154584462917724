import {
  DESTROY_CART_REQUEST,
  DESTROY_CART_SUCCESS,
  DESTROY_CART_FAIL,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";

import { fetchGetCart } from "./getCartActions";

const fetchDestroyCartBegin = () => ({
  type: DESTROY_CART_REQUEST,
});

const fetchDestroyCartSuccess = (message) => ({
  type: DESTROY_CART_SUCCESS,
  payload: { message },
});

const fetchDestroyCartFailure = (error) => ({
  type: DESTROY_CART_FAIL,
  payload: { error },
});

export const fetchDestroyCart = () => (dispatch) => {
  dispatch(fetchDestroyCartBegin());
  api
    .get("/api/destroy-cart")
    .then((res) => {
      if (res.data.success) {
        dispatch(fetchDestroyCartSuccess(res.data.message));
        dispatch(fetchGetCart());
      } else {
        dispatch(fetchDestroyCartFailure(res.data.message));
      }
    })
    .catch((error) => {
      dispatch(fetchDestroyCartFailure(error.message));
    });
};
