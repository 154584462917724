import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ProductItem from "../components/products/ProductItem";
import ProductLoading from "../components/products/ProductLoading";
import fetchShopAction from "../redux/actions/products/shopAction";
import { fetchFiltersAction } from "../redux/actions/filtersAction";
import { serialize } from "../shared/shared";
import ReactPixel from "react-facebook-pixel";
import { ModalContext } from "../components/modal/modalContext";
import DesktopFilters from "../components/shop/DesktopFilters"
import ActiveFilters from "../components/shop/ActiveFilters"
import "../css/shop.css";
const MobileFilters = lazy(() => import("../components/shop/MobileFilters"))

const SORT_CONSTANTS = {
  NAME: "nameAZ",
  NAME_DESC: "nameZA",
  PRICE: "priceMinMax",
  PRICE_DESC: "priceMaxMin",
  RELEVANCE: "relevance",
};

const ShopPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMounted = useRef(false);
  const { categorySlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [sort, setSort] = useState(searchParams.get("sort") ?? "RELEVANCE");
  const [tag, setTag] = useState(searchParams.get("tags[]") ?? null);
  const [page, setPage] = useState(searchParams.get("page"));
  const [show, setShow] = useState({});
  const click = useRef([]);
  const [filters, setFilters] = useState({});
  const [isFilterOpen, setFilterOpen] = useState(false);

  const shopState = useSelector((state) => state.products.shop);
  const filtersState = useSelector((state) => state.filters);
  const { handleModal } = React.useContext(ModalContext);

  useEffect(() => {
    dispatch(fetchShopAction(categorySlug, location.search));
  }, [categorySlug, location.search]);

  useEffect(() => {
    if (isMounted.current) setSearchParams(serializeFormQuery());
    else isMounted.current = true;
  }, [sort, filters, page,tag]);

  useEffect(() => {
    dispatch(fetchFiltersAction());
  }, []);

  useEffect(() => {
    if (
      filtersState.filters["categories"] &&
      filtersState.filters["categories"].length > 0
    ) {
      let obj = {};
      filtersState.filters["categories"].map(
        (category) => (obj[category.slug] = false)
      );
      setShow(obj);
    }
  }, [filtersState?.filters && filtersState?.filters["categories"]]);

  const handlePageClick = (e) => setPage(e.selected + 1);

  const handleFilterOpen = () => setFilterOpen(!isFilterOpen);

  const hadleSort = (e, key) => {
    e.preventDefault();
    setSort(key);
  };

  const serializeFormQuery = () => {
    let temps = { ...searchParams };
    if (sort != null && sort !== "relevance") temps["sort"] = sort;
    if (page != null && page !== 1) temps["page"] = page;
    if (tag != null) temps["tags[]"] = tag;
    if (searchParams.get("search"))
      temps["search"] = searchParams.get("search");
    let properties = [];
    Object.keys(filters["properties"] ?? {}).map((key) => {
      if (typeof filters["properties"][key] == "object") {
        properties = properties.concat(
          Object.values(filters["properties"][key])
        );
      }
    });
    temps["properties"] = properties;
    return serialize(temps);
  };

  useEffect(() => {
    if (searchParams.get("search") && shopState) {
      ReactPixel.track("Search", {
        content_type: "Products",
        content_ids: shopState.items.map((item) => item.id),
        search_string: searchParams.get("search"),
        contents: shopState.items.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      });
    }
  }, [shopState?.items]);

  return (
    <>
      <div className="custom-breadcrumb">
        <div className="container">
          <ol className="breadcrumb ">
            <li className="breadcrumb-item">
              <Link to="/">{t("home")}</Link>
            </li>
            {document.body.dir === "rtl" && (
              <span className="breadcrumb-separator">/</span>
            )}
            <li className="breadcrumb-item active">{t("shop")}</li>
          </ol>
        </div>
      </div>
      <section className="shop-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <DesktopFilters 
                click={click}
                show={show}
                setShow={setShow}
                filters={filters}
                setFilters={setFilters}
              />
              
              <Suspense fallback={<></>}>
                <MobileFilters 
                  click={click}
                  show={show}
                  setShow={setShow}
                  filters={filters}
                  setFilters={setFilters}
                  isFilterOpen={isFilterOpen}
                  handleFilterOpen={handleFilterOpen}
                />
              </Suspense>
            </div>
            <div className="col-lg-9 col-12">
              <ActiveFilters 
                filters={filters}
                setFilters={setFilters}
              />

              <div className="row">
                <div className="col-md-12">
                  <div className="sorting-filters-wrapper nav">
                    <div className="items-found">
                      <span>
                        {shopState.meta && (
                          <span>
                            {t("product_found", {
                              count: shopState.meta.total ?? 0,
                            })}
                          </span>
                        )}
                      </span>
                    </div>
                    <div
                      className={`d-flex align-items-center ${
                        document.body.dir === "ltr" ? "ml-auto" : ""
                      }`}
                      style={{
                        marginInlineStart:
                          document.body.dir === "rtl" && "auto",
                      }}
                    >
                      <button className="side-filters-toggler d-inline-block d-lg-none"
                        onClick={handleFilterOpen} aria-label="Filters">
                        <i className="fa fa-sliders-h" />
                      </button>

                      <div className="dropdown custom-dropdown sorting-filter-dropdown">
                        <a href="/#" data-toggle="dropdown">
                          {t(SORT_CONSTANTS[sort]) ??
                            t(SORT_CONSTANTS["RELEVANCE"])}{" "}
                          <i className="fa fa-caret-down mx-2" />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right mt-1">
                          <li
                            className={`dropdown-item ${
                              (sort === "" || sort == null) && "selected"
                            }`}
                          >
                            <a
                              href="/#"
                              onClick={(e) => hadleSort(e, "RELEVANCE")}
                            >
                              {t(SORT_CONSTANTS.RELEVANCE)}
                            </a>
                          </li>
                          <li
                            className={`dropdown-item ${
                              sort === "NAME" && "selected"
                            }`}
                          >
                            <a href="/#" onClick={(e) => hadleSort(e, "NAME")}>
                              {t(SORT_CONSTANTS.NAME)}
                            </a>
                          </li>
                          <li
                            className={`dropdown-item ${
                              sort === "NAME_DESC" && "selected"
                            }`}
                          >
                            <a
                              href="/#"
                              onClick={(e) => hadleSort(e, "NAME_DESC")}
                            >
                              {t(SORT_CONSTANTS.NAME_DESC)}
                            </a>
                          </li>
                          <li
                            className={`dropdown-item ${
                              sort === "PRICE" && "selected"
                            }`}
                          >
                            <a href="/#" onClick={(e) => hadleSort(e, "PRICE")}>
                              {t(SORT_CONSTANTS.PRICE)}
                            </a>
                          </li>
                          <li
                            className={`dropdown-item ${
                              sort === "PRICE_DESC" && "selected"
                            }`}
                          >
                            <a
                              href="/#"
                              onClick={(e) => hadleSort(e, "PRICE_DESC")}
                            >
                              {t(SORT_CONSTANTS.PRICE_DESC)}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {shopState.loading ? (
                  [...Array(3)].map((x, i) => (
                    <div
                      key={`loading-product-${i}`}
                      className="col-md-4 col-sm-6 col-12 col-lg-4"
                      style={{ marginBottom: "30px" }}
                    >
                      <ProductLoading />
                    </div>
                  ))
                ) : shopState.items.length > 0 ? (
                  shopState.items.map((item, index) => (
                    <div
                      className="col-md-4 col-sm-6 col-12 col-lg-4"
                      key={`product-${item.slug}-${index}`}
                    >
                      <ProductItem product={item} />
                    </div>
                  ))
                ) : (
                  <div className="col-md-12 text-center">
                    <i
                      className="fas fa-exclamation-triangle fa-5x"
                      style={{ marginBottom: "15px" }}
                    ></i>
                    <h1>{t("no_product_found")}</h1>
                  </div>
                )}
              </div>
              {!shopState.loading &&
                shopState.meta &&
                shopState.meta.current_page &&
                shopState.items.length > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="pagination-wrapper d-flex align-items-center">
                        <div className="shop-pagination">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel={`>`}
                            onPageChange={(e) => handlePageClick(e)}
                            containerClassName={"d-flex list-unstyled mb-0"}
                            pageClassName={"pagination-item"}
                            activeClassName={"active"}
                            initialPage={shopState.meta.current_page - 1}
                            pageRangeDisplayed={4}
                            marginPagesDisplayed={3}
                            pageCount={shopState.meta.last_page}
                            previousLabel={`<`}
                            renderOnZeroPageCount={null}
                            previousClassName="pagination-item pagination-previous"
                            nextClassName="pagination-item pagination-next"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div
          className={`${
            isFilterOpen
              ? "shop-mobile-cover shop-mobile-cover-show"
              : "shop-mobile-cover"
          }`}
          onClick={handleFilterOpen}
        />
      </section>
    </>
  );
};
export default ShopPage;
