import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../css/Layout/Header/linksNav.css";

const LinksNav = (props) => {
  const { t } = useTranslation();
  const storeInfosState = useSelector((state) => state.storeInfos.store_infos);
  const featuresState = useSelector((state) => state.features);

  return (
    <>
      <div className="second-navigation-bar">
        <div className="container">
          <div className="nav align-items-center justify-content-center">
            <div className="nav cart-menu-wrapper">
              <li className="nav-item d-none d-lg-inline-block">
                <NavLink to="/" className="nav-link">
                  {t("home")}
                </NavLink>
              </li>
              <li className="nav-item d-none d-lg-inline-block">
                <NavLink to="/shop" className="nav-link">
                  {t("shop")}
                </NavLink>
              </li>
              <li className="nav-item d-none d-lg-inline-block">
                <NavLink to="/about-us" className="nav-link">
                  {t("about_us")}
                </NavLink>
              </li>
              {featuresState?.features?.includes("contact") && (
                <li className="nav-item d-none d-lg-inline-block">
                  <NavLink to="/contact-us" className="nav-link">
                    {t("contact_us")}
                  </NavLink>
                </li>
              )}
            </div>
            {storeInfosState?.logo && (
              <Link to="/" className="second-navbar-brand d-block d-lg-none">
                <img
                  src={storeInfosState.logo}
                  alt={storeInfosState.name ?? ""}
                />
              </Link>
            )}
            <button className={`navbar-toggler d-inline-block d-lg-none ${document.body.dir === "ltr" ? "ml-auto" : "mr-auto"}`}
              onClick={() => props.setSideMenu(!props.sideMenu)} aria-label="Open menu">
              <i className="fa fa-bars"></i>
            </button>
          </div>
        </div>
      </div>
      <div className={`${props.sideMenu ? "side-main-menu side-main-menu-show" : "side-main-menu"} d-block d-lg-none`}>
        <div className="w-100 d-flex justify-content-end mb-1">
          <button className="ml-auto side-menu-close" aria-label="Close menu"
            onClick={() => props.setSideMenu(!props.sideMenu)}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div>
          <ul className="list-unstyled list-group">
            <li className="list-group-item">
              <Link
                to="/"
                style={{ textAlign: document.body.dir === "rtl" && "start" }}
              >
                {t("home")}
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/shop"
                style={{ textAlign: document.body.dir === "rtl" && "start" }}
              >
                {t("shop")}
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/about-us"
                style={{ textAlign: document.body.dir === "rtl" && "start" }}
              >
                {t("about_us")}
              </Link>
            </li>
            {featuresState?.features?.includes("contact") && (
              <li className="list-group-item">
                <Link
                  to="/contact-us"
                  style={{ textAlign: document.body.dir === "rtl" && "start" }}
                >
                  {t("contact_us")}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
export default LinksNav;
