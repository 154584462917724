import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import CategoryItem from "../categories/CategoryItem";
import ImageLoading from "../ImageLoading";
import "../../css/Home/categoriesCarousel.css"

const CategoriesCarousel = () => {
  const categoriesState = useSelector((state) => state.categories.featured);

  return (
    categoriesState?.loading ? (
      <ImageLoading width={"100%"} aspectRatio={4} marginTop={"40px"} />
    ) : (
      <section className="top-collection-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="top-collection-wrapper">
                <div className="row">
                  {categoriesState?.items?.length > 0 ? (
                    <OwlCarousel
                      className="col-md-12 top-collection-slider owl-carousel"
                      rtlClass={`${
                        document.body.dir === "rtl" ? "owl-rtl" : ""
                      }`}
                      autoplay
                      margin={15}
                      dots={false}
                      nav
                      autoWidth={false}
                      lazyLoad={true}
                      responsive={{
                        0: { items: 1 },
                        567: { items: 2 },
                        991: { items: 3 },
                      }}
                      key={"top-collection-slider"}
                    >
                      {categoriesState.items.map((item) => (
                        <div className="item" key={item.slug}>
                          <CategoryItem
                            category={{
                              name: item.name,
                              image: item.image,
                              slug: item.slug,
                            }}
                          />
                        </div>
                      ))}
                    </OwlCarousel>
                  ) : (
                    <ImageLoading width={"100%"} aspectRatio={4} marginTop={"40px"} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  );
};
export default CategoriesCarousel;
