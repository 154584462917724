import React, { lazy, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import BackToTop from "./BackToTop";
import { ModalProvider } from "../components/modal/modalContext"
import { InfoBanner } from "./header/InfoBanner";
import { useDispatch, useSelector } from "react-redux";
import fetchAboutUsAction from "../redux/actions/settings/aboutUsActions";
import fetchFlashSalesProductsAction from "../redux/actions/products/flashSalesProductsAction";
import TopNav from "./header/TopNav";
import LinksNav from "./header/LinksNav";
import RenderOnViewportEntry from "../components/RenderOnViewportEntry";

const Footer = lazy(() => import("./Footer"))

const AppLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [sideMenu, setSideMenu] = useState(false);

  const aboutUsState = useSelector((state) => state.aboutUs);
  const flashSalesState = useSelector((state) => state.products.flashSales);

  useEffect(() => {
    dispatch(fetchAboutUsAction());
    dispatch(fetchFlashSalesProductsAction());
  }, []);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(scrollToTop, [location.pathname]);

  return (
    <ModalProvider>
      {!aboutUsState.loading &&
        aboutUsState?.about_us?.info_banner &&
        aboutUsState?.about_us?.banner_colors?.info_banner_background_color && <InfoBanner
          position="relative"
          zIndex="1000"
          content={aboutUsState?.about_us?.info_banner}
          isMappable={false}
          backgroundColor={aboutUsState?.about_us?.banner_colors?.info_banner_background_color}
          color={null}
        />
      }
      <header>
        <TopNav />
        <LinksNav sideMenu={sideMenu} setSideMenu={setSideMenu} />
      </header>
      {!aboutUsState.loading &&
        !flashSalesState.loading && 
        flashSalesState.items &&
        aboutUsState?.about_us?.banner_colors?.sales_banner_background_color &&
        aboutUsState?.about_us?.banner_colors?.sales_banner_text_color &&
        <InfoBanner
          position="relative"
          zIndex="500"
          content={flashSalesState}
          isMappable={true}
          backgroundColor={aboutUsState?.about_us?.banner_colors?.sales_banner_background_color}
          color={aboutUsState?.about_us?.banner_colors?.sales_banner_text_color}
        />
      }
      <Outlet />
      <RenderOnViewportEntry
        threshold={0.25}
        style={{ minHeight: "200px" }}>
        <Footer />
      </RenderOnViewportEntry>
      <div className={`${sideMenu ? "body-cover-wrapper-show body-cover-wrapper" : "body-cover-wrapper"}`}
        onClick={() => setSideMenu(!sideMenu)}></div>
      <BackToTop />
    </ModalProvider>
  );
};

export default AppLayout;
