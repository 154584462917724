import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProductsCarousel from "../products/ProductsCarousel";
import productsActions from "../../redux/actions/products/productsActions";
import ImageLoading from "../ImageLoading";
import "../../css/Home/specialProducts.css";

const SpecialProductSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const topRated = useSelector((state) => state.products.topRated);
  const featured = useSelector((state) => state.products.featured);
  const featuresState = useSelector((state) => state.features);

  const [tab, setTab] = useState("");
  const handleTab = (tab) => setTab(tab);

  useEffect(() => {
    handleTab(
      featuresState?.features?.includes("featured-carousel") &&
        featured?.items?.length > 0
        ? "featured"
        : featuresState?.features?.includes("top-rated-carousel") &&
          topRated?.items.length > 0
        ? "top-rated"
        : ""
    );
  }, [featuresState?.features && featured?.items && topRated?.items]);

  useEffect(() => {
    if (featuresState?.features?.includes("featured-carousel"))
      dispatch(productsActions.fetchFeaturedProductsAction());
  }, [featuresState?.features?.includes("featured-carousel")]);

  useEffect(() => {
    if (featuresState?.features?.includes("top-rated-carousel"))
      dispatch(productsActions.fetchTopRatedProductsAction());
  }, [featuresState?.features?.includes("top-rated-carousel")]);

  useEffect(() => {
    featuresState?.features?.includes("featured-carousel") &&
    featured?.items?.length > 0
      ? handleTab("featured")
      : featuresState?.features?.includes("top-rated-carousel") &&
        topRated?.items.length > 0
      ? handleTab("top-rated")
      : handleTab("");
  }, [
    featured?.items?.length,
    featuresState?.features?.includes("featured-carousel"),
    topRated?.items?.length,
    featuresState?.features?.includes("top-rated-carousel"),
  ]);

  return (
    topRated?.loading || featured?.loading ? (
      <ImageLoading width={"100%"} aspectRatio={3} marginTop={"40px"} />
    ) : (
      (topRated?.items?.length > 0 || featured?.items?.length > 0) && (
        <section className="top-rated-section ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex w-100 top-rated-section-header">
                  <h3 className="d-none d-md-block">
                    {t("special_products")}
                  </h3>
                  <ul
                    className="nav nav-pills ml-auto mr-auto"
                    id="featured-products-nav-pills"
                    role="tablist"
                  >
                    {featuresState?.features?.includes("featured-carousel") &&
                      featured?.items?.length > 0 && (
                        <li className="nav-item" role="tab">
                          <a
                            className={`nav-link ${
                              tab === "featured" ? "active show" : ""
                            }`}
                            id="pills-featured-tab"
                            data-toggle="pill"
                            href="#featured-tab"
                            role="tab"
                            aria-controls="featured-tab"
                            aria-selected={`${
                              tab === "featured" ? "true" : "false"
                            }`}
                            onClick={() => handleTab("featured")}
                          >
                            {t("featured_products")}
                          </a>
                        </li>
                      )}
                    {featuresState?.features?.includes(
                      "top-rated-carousel"
                    ) &&
                      topRated?.items?.length > 0 && (
                        <li className="nav-item" role="tab">
                          <a
                            className={`nav-link ${
                              tab === "top-rated" ? "active show" : ""
                            }`}
                            id="pills-topRated-tab"
                            data-toggle="pill"
                            href="#topRated-tab"
                            role="tab"
                            aria-controls="topRated-tab"
                            aria-selected={`${
                              tab === "top-rated" ? "true" : "false"
                            }`}
                            onClick={() => handleTab("top-rated")}
                          >
                            {t("top_rated")}
                          </a>
                        </li>
                      )}
                  </ul>
                </div>
                <div className="tab-content">
                  {featuresState?.features?.includes("featured-carousel") && (
                    <div
                      className={`tab-pane fade ${
                        tab === "featured" ? "show active" : "show"
                      }`}
                      id="featured-tab"
                      role="tabpanel"
                      aria-labelledby="pills-featured-tab"
                    >
                      <div className="row">
                        <ProductsCarousel
                          className="new-products-slider"
                          componentKey="new-products-slider"
                          products={featured}
                        />
                      </div>
                    </div>
                  )}
                  {featuresState?.features?.includes(
                    "top-rated-carousel"
                  ) && (
                    <div
                      className={`tab-pane fade ${
                        tab === "top-rated" ? "show active" : "show"
                      }`}
                      id="topRated-tab"
                      role="tabpanel"
                      aria-labelledby="pills-topRated-tab"
                    >
                      <div className="row">
                        <ProductsCarousel
                          className="new-products-slider"
                          componentKey="top-rated-products-slider"
                          products={topRated}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    )
  );
};

export default SpecialProductSection;
