import {
  NEW_PRODUCTS_FAIL,
  NEW_PRODUCTS_REQUEST,
  NEW_PRODUCTS_SUCCESS,
} from "../../../shared/constants";

import { api } from "../../../shared/shared";

const fetchNewProductsBegin = () => ({
  type: NEW_PRODUCTS_REQUEST,
});

const fetchNewProductsSuccess = (products) => ({
  type: NEW_PRODUCTS_SUCCESS,
  payload: { products },
});

const fetchNewProductsFailure = (error) => ({
  type: NEW_PRODUCTS_FAIL,
  payload: { error },
});

const fetchNewProductsAction = () => (dispatch) => {
  dispatch(fetchNewProductsBegin());
  api
    .get("api/products/newest")
    .then((res) =>
      res.data.success
        ? dispatch(fetchNewProductsSuccess(res.data.data))
        : dispatch(fetchNewProductsFailure(res.data.message))
    )
    .catch((error) => dispatch(fetchNewProductsFailure(error.message)));
};

export default fetchNewProductsAction;
