import {
  CITIES_REQUEST,
  CITIES_SUCCESS,
  CITIES_FAIL,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";

const fetchCitiesBegin = () => ({
  type: CITIES_REQUEST,
});

const fetchCitiesSuccess = (cities) => ({
  type: CITIES_SUCCESS,
  payload: { cities },
});

const fetchCitiesFailure = (error) => ({
  type: CITIES_FAIL,
  payload: { error },
});

const fetchCitiesAction = (wilaya) => (dispatch) => {
  dispatch(fetchCitiesBegin());
  api
    .get("/api/cities?wilaya=" + wilaya)
    .then((res) => {
      if (res.data.success) dispatch(fetchCitiesSuccess(res.data.data));
      else dispatch(fetchCitiesFailure(res.data.message));
    })
    .catch((error) => {
      dispatch(fetchCitiesFailure(error.response.data.message));
    });
};

export default fetchCitiesAction;
