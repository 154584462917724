import React, { useState } from "react";
import ImageLoading from "../ImageLoading";

const CarouselImage = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <link
        rel="preload"
        as="image"
        href={props.image}
        imageSrcSet={`${props.image}?w=260&h=260 1440w,${props.image}?w=190&h=260 1024w,${props.image}?w=130&h=260 768w,${props.image}?w=350&h=260 425w,`}
        imageSizes="
          (max-width: 425px) 350px,
          (max-width: 768px) 130px,
          (max-width: 1024px) 190px,
          260px"
        onLoad={() => setLoaded(true)}
        style={{ display: "none" }}
        alt={props.image}
      />
      {loaded ? (
        <img
          srcSet={`${props.image}?w=450&h=450 1440w,${props.image}?w=400&h=400 1024w,${props.image}?w=350&h=350 768w,${props.image}?w=350&h=260 425w,`}
          sizes="
            (max-width: 425px) 350px,
            (max-width: 768px) 350px,
            (max-width: 1024px) 400px,
            450px"
          alt={props.image}
        />
      ) : (
        <ImageLoading width={"100%"} height={"260px"} />
      )}
    </>
  );
};

export default CarouselImage;
