import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ImageLoading from "../components/ImageLoading";
import CategoriesCarousel from "../components/Home/CategoriesCarousel";
import SpecialProductSection from "../components/Home/SpecialProductSection";
import CategorySection from "../components/Home/CategorySection";
import UniversalSection from "../components/Home/UniversalSection";
import ReactPixel from "react-facebook-pixel";
import Slider from "../components/Home/Slider";
import fetchNewProductsAction from "../redux/actions/products/newProductsActions";
import fetchBestSellingProductsAction from "../redux/actions/products/bestSellingProductsAction";
import "../css/home.css";

const HomePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const newArrival = useSelector((state) => state.products.newest);
  const bestSelling = useSelector((state) => state.products.bestSelling);
  const featuresState = useSelector((state) => state.features);

  useEffect(() => {
    ReactPixel.pageView()
  }, []);

  useEffect(() => {
    if(featuresState?.features?.includes("newest-carousel"))
      dispatch(fetchNewProductsAction());
  }, [featuresState?.features?.includes('newest-carousel')]);

  useEffect(() => {
    if(featuresState?.features?.includes("best-selling-carousel"))
      dispatch(fetchBestSellingProductsAction());
  }, [featuresState?.features?.includes('best-selling-carousel')]);

  return (
    <>
      {featuresState?.features?.includes("slider") ? <Slider />
        : featuresState?.loading && <ImageLoading width={"100%"} aspectRatio={2.67} />}

      {featuresState?.features?.includes("categories-carousel") ? <CategoriesCarousel />
        : featuresState?.loading && <ImageLoading width={"100%"} aspectRatio={4} marginTop={"40px"} />}
      
      {(featuresState?.features?.includes("featured-carousel") 
        || featuresState?.features?.includes("top-rated-carousel")) ? <SpecialProductSection />
      : featuresState?.loading && <ImageLoading width={"100%"} aspectRatio={3} marginTop={"40px"} />}
    
      {featuresState?.features?.includes("categories-carousel") ? <CategorySection /> 
      : featuresState?.loading && <ImageLoading width={"100%"} aspectRatio={4} marginTop={"40px"} />}

      {/* New Products */}
      {featuresState?.features?.includes("newest-carousel") ? 
        <UniversalSection carouselKey="newest" title={t("new_arrival")} products={newArrival} />
      : featuresState?.loading && <ImageLoading width={"100%"} aspectRatio={4} marginTop={"40px"} />}
      
      {/* Best selling Products */}
      {featuresState?.features?.includes("best-selling-carousel") ? 
        <UniversalSection carouselKey="best-selling" title={t("best_selling")} products={bestSelling} />
      : featuresState?.loading && <ImageLoading width={"100%"} aspectRatio={4} marginTop={"40px"} />}
    </>
  );
};

export default HomePage;

