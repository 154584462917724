import {
  BEST_SELLING_PRODUCTS_FAIL,
  BEST_SELLING_PRODUCTS_REQUEST,
  BEST_SELLING_PRODUCTS_SUCCESS,
} from "../../../shared/constants";

import { api } from "../../../shared/shared";

const fetchBestSellingBegin = () => ({
  type: BEST_SELLING_PRODUCTS_REQUEST,
});

const fetchBestSellingSuccess = (products) => ({
  type: BEST_SELLING_PRODUCTS_SUCCESS,
  payload: { products },
});

const fetchBestSellingFailure = (error) => ({
  type: BEST_SELLING_PRODUCTS_FAIL,
  payload: { error },
});

const fetchBestSellingProductsAction = () => (dispatch) => {
  dispatch(fetchBestSellingBegin());
  api
    .get("api/products/best-selling")
    .then((res) =>
      res.data.success
        ? dispatch(fetchBestSellingSuccess(res.data.data))
        : dispatch(fetchBestSellingFailure(res.data.message))
    )
    .catch((error) => dispatch(fetchBestSellingFailure(error.message)));
};

export default fetchBestSellingProductsAction;
