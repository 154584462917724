import React from "react";
import { getPercentageWidth, number_format } from "../../shared/shared";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useWholesale from "../../hooks/useWholesale";

export const NamePriceRating = (props) => {
  const { t } = useTranslation();
  const featuresState = useSelector((state) => state.features);
  const settingsState = useSelector((state) => state.storeInfos);
  const productRatingsState = useSelector((state) => state.products.ratings);
  const {getWhsalePrice} = useWholesale(props.product)
  let arabic = /[\u0600-\u06FF]/;

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3 className="product-details-product-name mt-1">{props.name}</h3>
      </div>
      <div className="d-flex align-items-center">
        {props.short_description && (
          <>
            <p
              className={`product-details-product-description
              ${arabic.test(props.short_description) ? "text-right" : "text-left"}`}
            >
              {props.short_description}
            </p>
          </>
        )}
      </div>
      {props.selectedOption ? (
        props.selectedOption.special_price ? (
          <div className="d-flex flex-column flex-sm-row align-items-sm-center">
            {settingsState?.store_infos?.currency ? (
              settingsState.store_infos.currency !== "DZD" ? (
                <>
                  <span
                    className={`product-details-product-price ${
                      document.body.dir === "rtl" ? "ml-2" : "mr-2"
                    }`}
                  >
                    {t("currency", {
                      price: number_format(props.selectedOption.special_price),
                      currency: settingsState.store_infos.currency,
                    })}
                  </span>
                  <div className="d-flex align-items-center mb-2 mb-sm-0">
                    <span className="product-details-product-old-price">
                      {t("currency", {
                        price: number_format(props.selectedOption.price),
                        currency: settingsState.store_infos.currency,
                      })}
                    </span>
                    <span className="price-promotion">
                      -{(100 - (props.special_price / props.price) * 100).toFixed(2)}%
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <span
                    className={`product-details-product-price ${
                      document.body.dir === "rtl" ? "ml-2" : "mr-2"
                    }`}
                  >
                    {t("DA", {
                      price: number_format(props.selectedOption.special_price),
                    })}
                  </span>
                  <div className="d-flex align-items-center mb-2 mb-sm-0">
                    <span className="product-details-product-old-price">
                      {t("DA", {
                        price: number_format(props.selectedOption.price),
                      })}
                    </span>
                    <span className="price-promotion">
                      -{(100 - (props.special_price / props.price) * 100).toFixed(2)}%
                    </span>
                  </div>
                </>
              )
            ) : (
              <>
                <span
                  className={`product-details-product-price ${
                    document.body.dir === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {number_format(props.selectedOption.special_price)}
                </span>
                <div className="d-flex align-items-center mb-2 mb-sm-0">
                  <span className="product-details-product-old-price">
                    {number_format(props.selectedOption.price)}
                  </span>
                  <span className="price-promotion">
                    -{(100 - (props.special_price / props.price) * 100).toFixed(2)}%
                  </span>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <span
              className={`product-details-product-price ${
                document.body.dir === "rtl" ? "ml-2" : "mr-2"
              }`}
            >
              {settingsState?.store_infos?.currency
                ? settingsState.store_infos.currency !== "DZD"
                  ? t("currency", {
                      // price: number_format(props.selectedOption.price),
                      price: number_format(getWhsalePrice(props.quantity, props.selectedOption.price, props.selectedOption)),
                      currency: settingsState.store_infos.currency,
                    })
                  : t("DA", {
                      // price: number_format(props.selectedOption.price),
                      price: number_format(getWhsalePrice(props.quantity, props.selectedOption.price, props.selectedOption)),
                    })
                : number_format(getWhsalePrice(props.quantity, props.selectedOption.price, props.selectedOption))}
            </span>
          </div>
        )
      ) : props.special_price ? (
        <div className="d-flex flex-column flex-sm-row align-items-sm-center">
          {settingsState?.store_infos?.currency ? (
            settingsState.store_infos.currency !== "DZD" ? (
              <>
                <span
                  className={`product-details-product-price ${
                    document.body.dir === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {t("currency", {
                    price: number_format(props.special_price),
                    currency: settingsState.store_infos.currency,
                  })}
                </span>
                <div className="d-flex align-items-center mb-2 mb-sm-0">
                  <span className="product-details-product-old-price">
                    {t("currency", {
                      price: number_format(props.price),
                      currency: settingsState.store_infos.currency,
                    })}
                  </span>
                  <span className="price-promotion">
                    -{(100 - (props.special_price / props.price) * 100).toFixed(2)}%
                  </span>
                </div>
              </>
            ) : (
              <>
                <span
                  className={`product-details-product-price ${
                    document.body.dir === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {t("DA", {
                    price: number_format(props.special_price),
                  })}
                </span>
                <div className="d-flex align-items-center mb-2 mb-sm-0">
                  <span className="product-details-product-old-price">
                    {t("DA", {
                      price: number_format(props.price),
                    })}
                  </span>
                  <span className="price-promotion">
                    -{(100 - (props.special_price / props.price) * 100).toFixed(2)}%
                  </span>
                </div>
              </>
            )
          ) : (
            <>
              <span
                className={`product-details-product-price ${
                  document.body.dir === "rtl" ? "ml-2" : "mr-2"
                }`}
              >
                {number_format(props.special_price)}
              </span>
              <div className="d-flex align-items-center mb-2 mb-sm-0">
                <span className="product-details-product-old-price">
                  {number_format(props.price)}
                </span>
                <span className="price-promotion">
                  -{(100 - (props.special_price / props.price) * 100).toFixed(2)}%
                </span>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <span
            className={`product-details-product-price ${
              document.body.dir === "rtl" ? "ml-2" : "mr-2"
            }`}
          >
            {settingsState?.store_infos?.currency
              ? settingsState.store_infos.currency !== "DZD"
                ? t("currency", {
                    // price: number_format(props.price),
                    price: number_format(getWhsalePrice(props.quantity, props.price)),
                    currency: settingsState.store_infos.currency,
                  })
                : t("DA", {
                    // price: number_format(props.price),
                    price: number_format(getWhsalePrice(props.quantity, props.price)),
                  })
              : number_format(getWhsalePrice(props.quantity, props.price))}
          </span>
        </div>
      )}

      {featuresState?.features?.includes("reviews") &&
        productRatingsState?.reviews_avg && (
          <div className="total-product-rate mx-2 d-flex flex-row">
            <div className="rating_wrap" style={{ width: "123px" }}>
              <div className="rating">
                <div
                  className="current-rate"
                  style={{
                    width: `${getPercentageWidth(
                      productRatingsState.reviews_avg
                    )}%`,
                  }}
                />
              </div>
            </div>
            <h6 style={{ margin: "0.25rem 0.5rem" }}>
              ({productRatingsState.reviews_avg})
            </h6>
          </div>
        )}
    </>
  );
};
