import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchDestroyCart } from "../../redux/actions/cart/destroyCartActions";
import { number_format } from "../../shared/shared";
import ImageLoading from "../ImageLoading";
import CartItem from "./CartItem";
import CartItemLoading from "./CartItemLoading";
import "../../css/Layout/Header/sideCart.css";
import useWholesale from "../../hooks/useWholesale";

const SideCart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cartState = useSelector((state) => state.cart.getCart);
  const destroyCartState = useSelector((state) => state.cart.destroyCart);
  const settingsState = useSelector((state) => state.storeInfos);
  const { getWhsalePrice } = useWholesale();

  // const cartTotal = (total, item) => total + item.price * item.qty;
  const cartTotal = (total, item) =>
    total +
    getWhsalePrice(item.qty, item.price, item?.special_price ? "" : item) *
      item.qty;

  const handleClearCart = (event) => {
    event.stopPropagation();
    cartState && cartState.items.length > 0 && dispatch(fetchDestroyCart());
  };

  return (
    <li className="nav-item dropdown shopping-cart cart-dropdown">
      <a
        href="/#"
        className="nav-link"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="material-icons-outlined">shopping_cart</span>
        <span className="cart-counter">{cartState.items.length}</span>
      </a>
      {cartState && (
        <ul className="dropdown-menu dropdown-menu-right">
          <li className="dropdown-item cart-items-wrapper">
            {cartState.loading ? (
              [...Array(2)].map((item, index) => (
                <CartItemLoading key={index} />
              ))
            ) : cartState.items.length === 0 ? (
              <div className="cart-item d-flex ">
                <div className="cart-item-details">
                  <div className="cart-item-product-name">
                    {t("cart_items_count_zero")}
                  </div>
                </div>
              </div>
            ) : (
              cartState.items.map((item) => (
                <CartItem item={item} key={item.rowId} />
              ))
            )}
          </li>
          <div className="dropdown-divider"></div>
          <div className="cart-total-wrapper">
            <div
              className="w-100"
              style={{ textAlign: document.body.dir === "rtl" && "right" }}
            >
              {cartState.loading ? (
                <ImageLoading width={"88px"} aspectRatio={"88/17"} />
              ) : cartState.items.length === 1 ? (
                <p>{t("items_count_one", { count: cartState.items.length })}</p>
              ) : cartState.items.length > 1 ? (
                <p>
                  {t("items_count_other", { count: cartState.items.length })}
                </p>
              ) : (
                ""
              )}

              <div className="d-flex">
                <span>{t("total")}</span>
                <span className="ml-auto cart-total-price mr-1">
                  {settingsState?.store_infos?.currency
                    ? settingsState.store_infos.currency !== "DZD"
                      ? t("currency", {
                          price: number_format(
                            cartState.items.reduce(cartTotal, 0)
                          ),
                          currency: settingsState.store_infos.currency,
                        })
                      : t("DA", {
                          price: number_format(
                            cartState.items.reduce(cartTotal, 0)
                          ),
                        })
                    : number_format(cartState.items.reduce(cartTotal, 0))}
                </span>
              </div>
            </div>
          </div>
          <div className="dropdown-divider"></div>
          <div className="d-flex cart-action-btn-wrapper">
            <button
              className="remove-all-btn cancel-btn"
              aria-label="Clear cart"
              onClick={handleClearCart}
              disabled={destroyCartState.loading}
            >
              {t("clear_cart")}
              {destroyCartState.loading && (
                <span
                  className={`spinner-border spinner-border-sm ${
                    document.body.dir === "rtl" ? "mr-2" : "ml-2"
                  }`}
                  role="status"
                  aria-hidden="false"
                ></span>
              )}
            </button>
            <Link to="/checkout" className="submit-btn view-cart-btn">
              {t("checkout")}
            </Link>
          </div>
        </ul>
      )}
    </li>
  );
};
export default SideCart;
