import {
  SLIDER_FAIL,
  SLIDER_REQUEST,
  SLIDER_SUCCESS,
} from "../../shared/constants";
import { api } from "../../shared/shared";

const fetchSliderBegin = () => ({
  type: SLIDER_REQUEST,
});

const fetchSliderSuccess = (sliders) => ({
  type: SLIDER_SUCCESS,
  payload: { sliders },
});

const fetchSliderFailure = (error) => ({
  type: SLIDER_FAIL,
  payload: { error },
});

const fetchSliderAction = () => (dispatch) => {
  dispatch(fetchSliderBegin());
  api
    .get("/api/sliders")
    .then((res) => {
      dispatch(fetchSliderSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchSliderFailure(error.message));
    });
};

export default fetchSliderAction;
