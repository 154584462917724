import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { number_format } from "../../shared/shared";
import AddToCartModal from "../cart/AddToCartModal";
import AlertModal from "../AlertModal";
import { ModalContext } from "../modal/modalContext";
import { fetchAddToWishlistAction } from "../../redux/actions/wishlist/addActions";
import { useDispatch, useSelector } from "react-redux";
import ReactPixel from "react-facebook-pixel";
import "../../css/Product/productItem.css";
import CarouselImage from "./CarouselImage";

const ProductItem = (props) => {
  const { handleModal } = React.useContext(ModalContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth.authenticated);
  const settingsState = useSelector((state) => state.storeInfos);
  const featuresState = useSelector((state) => state.features);

  const getDiscount = (price, special_price) =>
    Math.floor(100 - (special_price * 100) / price);

  const handleWishlist = (id) => {
    if (authState.user) {
      dispatch(fetchAddToWishlistAction(id))
        .then((res) => {
          ReactPixel.track("AddToWishlist", {
            content_type: "product",
            contents: [
              {
                id: id,
                price: props.product.price,
                name: props.product.name,
              },
            ],
          });
          handleModal(
            <AlertModal
              variant="success"
              title={t("add_product_to_wishlist")}
              message={t("Product added to the wishlist successfully")}
            />
          );
        })
        .catch((err) =>
          handleModal(
            <AlertModal
              variant="danger"
              title={t("add_product_to_wishlist")}
              message={t("Couldn't add the product to the wishlist")}
            />
          )
        );
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="product-card">
      <div className="product-tags">
        <div className="d-flex align-items-center justify-content-between">
          {props.product.special_price && (
            <span className="sale-tag">
              -{getDiscount(props.product.price, props.product.special_price)}%
            </span>
          )}
          {props.product.new ? (
            <span
              className={`new-tag ${document.body.dir === "ltr" && "ml-auto"}`}
            >
              {t("new")}
            </span>
          ) : (
            <span style={{ height: "26px" }}></span>
          )}
        </div>
      </div>

      <div className="product-card-img-wrapper d-flex">
        {featuresState?.features?.includes("wishlist") && (
          <button
            title={t("add_product_to_wishlist")}
            className="add-to-wishlist-btn"
            aria-label="Add to wishlist"
            onClick={() => handleWishlist(props.product.id)}
          >
            <i className="far fa-heart" />
          </button>
        )}
        <Link
          to={"/shop/" + props.product.slug}
          style={{ width: "100%", height: "100%" }}
        >
          <CarouselImage image={props.product.image} />
        </Link>
      </div>
      <div className="product-card-details">
        <p className="product-name text-center">
          <Link to={"/shop/" + props.product.slug}>{props.product.name}</Link>
        </p>
        <div className="d-flex justify-content-center align-items-baseline">
          {props.product.special_price ? (
            <div className="d-flex align-items-baseline">
              {settingsState?.store_infos?.currency ? (
                settingsState.store_infos.currency !== "DZD" ? (
                  <>
                    <span className="product-old-price">
                      {t("currency", {
                        price: number_format(props.product.price),
                        currency: settingsState.store_infos.currency,
                      })}
                    </span>
                    <span className="product-price mx-2">
                      {t("currency", {
                        price: number_format(props.product.special_price),
                        currency: settingsState.store_infos.currency,
                      })}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="product-old-price">
                      {t("DA", { price: number_format(props.product.price) })}
                    </span>
                    <span className="product-price mx-2">
                      {t("DA", {
                        price: number_format(props.product.special_price),
                      })}
                    </span>
                  </>
                )
              ) : (
                <>
                  <span className="product-old-price">
                    {number_format(props.product.price)}
                  </span>
                  <span className="product-price mx-2">
                    {number_format(props.product.special_price)}
                  </span>
                </>
              )}
            </div>
          ) : (
            <div className="d-flex align-items-baseline">
              <span className="product-price">
                {settingsState?.store_infos?.currency
                  ? settingsState.store_infos.currency !== "DZD"
                    ? t("currency", {
                        price: number_format(props.product.price),
                        currency: settingsState.store_infos.currency,
                      })
                    : t("DA", { price: number_format(props.product.price) })
                  : number_format(props.product.price)}
              </span>
            </div>
          )}
        </div>
      </div>
      {featuresState?.features?.includes("add-to-cart") && (
        <div className="product-card-action-btn d-flex">
          <a
            href="/#"
            className="add-to-cart-btn"
            onClick={(e) => {
              e.preventDefault();
              handleModal(<AddToCartModal product={props.product} />);
            }}
          >
            {document.body.dir === "ltr" ? (
              <>
                <i className="fa fa-shopping-cart mr-2"></i>
                {t("add to cart")}
              </>
            ) : (
              <>
                {t("add to cart")}
                <i className="fa fa-shopping-cart mr-2"></i>
              </>
            )}
          </a>
        </div>
      )}
    </div>
  );
};

export default ProductItem;
