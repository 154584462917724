import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import "../../css/Layout/Header/infoBanner.css";

export const InfoBanner = (props) => {
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(true);
  const handleClose = () => setShowBanner(false);

  let arabic = /[\u0600-\u06FF]/;
  const [bannerTimes, setBannerTimes] = useState([]);

  const calculateTimeLeft = (target) => {
    const now = new Date();
    const targetTime = new Date(target);
    const difference = targetTime - now;

    if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(() => {
    const initialTimeLeft = {};
    if (props.content?.items?.length > 0 && bannerTimes.length > 0) {
      bannerTimes.forEach((targetDate) => {
        initialTimeLeft[targetDate] = calculateTimeLeft(targetDate);
      });
    }
    return initialTimeLeft;
  });

  useEffect(() => {
    if (props.content?.items?.length > 0) {
      setBannerTimes(() =>
        props.content.items.map((item) => {
          return item.promotion.end_date;
        })
      );
    }
  }, [props.content?.items?.length]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        const updatedTimeLeft = { ...prevTimeLeft };
        if (props.content?.items?.length > 0 && bannerTimes?.length > 0) {
          bannerTimes.forEach((targetDate) => {
            updatedTimeLeft[targetDate] = calculateTimeLeft(targetDate);
          });
        }
        return updatedTimeLeft;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [bannerTimes, props.content?.items?.length]);

  const getReductionPercentage = (price, special_price) => {
    let difference = price - special_price;
    let division = difference / price;
    return Math.round(division * 100);
  };

  return (
    showBanner &&
    props.content && (
      <div
        className="info-banner"
        style={{
          zIndex: props.zIndex,
          position: props.position,
          backgroundColor: props.backgroundColor ?? "#60be74",
          color: props.color,
        }}
      >
        {props.isMappable ? (
          <div
            className="moving-text"
            style={{
              animationDirection:
                document.body.dir === "rtl"
                  ? "normal" : "reverse",
            }}
          >
            {props.content &&
              props.content.items &&
              props.content.items.length > 0 &&
              props.content?.items?.map((item, index) => (
                <span className="mx-3" key={"flash-sale-" + index}>
                  {item.name} &nbsp;
                  {getReductionPercentage(item.price, item.special_price)}%
                  &nbsp;
                  {t("time_left", {
                    days: timeLeft[item.promotion.end_date]?.days,
                    hours: timeLeft[item.promotion.end_date]?.hours,
                    minutes: timeLeft[item.promotion.end_date]?.minutes,
                    seconds: timeLeft[item.promotion.end_date]?.seconds,
                  })}
                </span>
              ))}
          </div>
        ) : (
          <div
            className="moving-text"
            style={{
              animationDirection: arabic.test(props.content)
                ? "normal" : "reverse",
            }}
          >
            {parse(props.content)}
          </div>
        )}
        <button className="close-banner" onClick={handleClose} aria-label="Close banner">
          <i className="fa fa-times" />
        </button>
      </div>
    )
  );
};
