import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchSliderAction from "../../redux/actions/sliderAction";
import OwlCarousel from "react-owl-carousel";
import ImageLoading from "../ImageLoading";
import "../../css/Home/slider.css";
import SliderImage from "./SliderImage";

const Slider = () => {
  const dispatch = useDispatch();
  const sliderState = useSelector((state) => state.slider);

  useEffect(() => {
    dispatch(fetchSliderAction());
  }, []);

  return (
    sliderState?.loading ? (
      <ImageLoading width={"100%"} aspectRatio={2.67} />
    ) : (
      <section className="main-slider">
        <div className="container-fluid">
          <div className="row justify-content-end">
            {sliderState?.sliders?.length > 0 ? (
              <OwlCarousel
                className=" col-12 col-md-12 owl-carousel main-slider-owl-carousel"
                nav
                autoplay
                margin={15}
                items={1}
                rtlClass={`${document.body.dir === "rtl" ? "owl-rtl" : ""}`}
                autoWidth={false}
                key={"owl-carousel-slider"}
              >
                {sliderState.sliders.map((item, i) => (
                  <div className="slider-wrapper item" key={item.url + i}>
                    <SliderImage image={item.url} />
                  </div>
                ))}
              </OwlCarousel>
              ) : (
                <ImageLoading width={"100%"} aspectRatio={2.67} />
              )}
          </div>
        </div>
      </section>
    )
  );
};

export default Slider;
