import React from "react";
import { Link } from "react-router-dom";
import CategoryImage from "../Home/CategoryImage";

const CategoryItem = (props) => {
  return (
    <div className="collection-content">
      <Link to={"/categories/" + props.category.slug}>
        <CategoryImage image={props.category.image} />
        <h3 className="category-name">{props.category.name}</h3>
      </Link>
    </div>
  );
};

export default CategoryItem;
