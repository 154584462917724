import { useSelector } from "react-redux";

const useWholesale = (whItems) => {
  const featuresState = useSelector((state) => state.features);

  const getWhsalePrice = (quantity, originalPrice, item) => {
    if (
      !featuresState?.features?.includes("wholesale") ||
      whItems?.special_price
    )
      return originalPrice;
    else if (item) {
      const range = item?.wholesale.find(
        (range) =>
          (range.min_quantity &&
            (range.max_quantity || item.stock) &&
            quantity >= range.min_quantity &&
            quantity <= (range.max_quantity || item.stock)) ||
          (range.start_quantity &&
            (range.end_quantity || item.stock) &&
            quantity >= range.start_quantity &&
            quantity <= (range.end_quantity || item.stock))
      );
      return range ? parseFloat(range.price) : originalPrice;
    } else {
      const range = whItems?.wholesale?.find(
        (range) =>
          (range.min_quantity &&
            (range.max_quantity || whItems.stock) &&
            quantity >= range.min_quantity &&
            quantity <= (range.max_quantity || whItems.stock)) ||
          (range.start_quantity &&
            (range.end_quantity || whItems.stock) &&
            quantity >= range.start_quantity &&
            quantity <= (range.end_quantity || whItems.stock))
      );
      return range ? range.price : originalPrice;
    }
  };

  return {
    getWhsalePrice,
  };
};

export default useWholesale;
