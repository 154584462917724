import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ModalContext } from "./modal/modalContext";
import "../css/Modal/default.css"

const AlertModal = ({ variant, title, message }) => {
  const { handleModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const handleCloseModal = () => handleModal();
  const MESSAGE_TYPE = {
    "success": "success",
    "danger": "danger"
  };

  return (
  <div
    className="modal fade show"
    style={{ display: "block", paddingRight: "17px" }}
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content px-3">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {title}
          </h5>
          <button
            type="button"
            className="modal-dismiss"
            data-dismiss="modal"
            aria-label="Close modal"
            onClick={handleCloseModal}
          >
            <span>
              <i className="fa fa-times" />
            </span>
          </button>
        </div>

        <div className="modal-body">
          <div className="row justify-content-center">
            <div className="order-success-wrapper text-center">
              {variant === MESSAGE_TYPE["success"] && 
                <>
                  <h3>{message}</h3>
                  <div className="mb-3">
                    <i className="fa fa-check" />
                  </div>
                </>
              }
              {variant === MESSAGE_TYPE["danger"] &&
                <>
                  <h3>{message}</h3>
                  <div className="mb-3">
                    <i className="fa fa-times" />
                  </div>
                </>
              }
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            aria-label="Close modal"
            className="btn btn-secondary"
            onClick={handleCloseModal}
          >
            {t("close")}
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};
export default AlertModal;
