import React, { useEffect, useState } from "react";
import "../css/Layout/Footer/backTop.css";

const BackToTop = () => {
  const [show, handleShow] = useState(false);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY < 300) handleShow(true);
      else handleShow(false);
    });
    return () => window.removeEventListener("scroll", null);
  }, []);

  return (
    <button
      className={`back-to-top-btn ${show && "d-none"}`}
      style={{
        right: "unset",
        left: "20px",
      }}
      aria-label="Scroll top"
      onClick={scrollToTop}
    >
      <i className="fa fa-chevron-up"></i>
    </button>
  );
};
export default BackToTop;
