import { useEffect, useRef, useState } from "react";

const useRecaptcha = () => {
  const recaptchaRef = useRef();
  const [recaptcha, setRecaptcha] = useState("");
  const [recaptchaError, setRecaptchaError] = useState(null);

  const handleExpiration = () => {
    setRecaptchaError("Recaptcha expiration error");
    if(recaptchaRef?.current?.getWidgetId())
      recaptchaRef?.current?.reset()
    handleChange("");
  }

  const handleChange = (token) => {
    if(token === "" || recaptcha === null || recaptcha === "") {
      recaptchaRef?.current?.execute()
      setRecaptcha(token)
    }
  }

  const executeRecaptcha = () => {
    if(recaptchaRef?.current?.getWidgetId())
      recaptchaRef?.current?.reset()
    recaptchaRef?.current?.execute()
    setRecaptcha(recaptchaRef?.current?.getValue())
  }

  const checkRecaptchaAsync = async () => {
    recaptchaRef?.current?.executeAsync()
    .then((token) => setRecaptcha(token ?? recaptchaRef?.current?.getValue()))
    .catch(() => executeRecaptcha(null))
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRecaptchaError(null);
    }, 10000);
  
    return () => clearTimeout(timeout)
  }, [recaptchaError])

  return {
    recaptchaRef,
    recaptcha,
    recaptchaError,
    handleChange,
    handleExpiration,
    executeRecaptcha,
    checkRecaptchaAsync,
  };
};

export default useRecaptcha;