import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchRemoveItemFromCart } from "../../redux/actions/cart/removeItemActions";
import { number_format } from "../../shared/shared";
import CartItemImage from "./CartItemImage";
import useWholesale from "../../hooks/useWholesale";

const CartItem = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const settingsState = useSelector((state) => state.storeInfos);
  const { getWhsalePrice } = useWholesale();

  const handleDeleteItem = (event) => {
    event.preventDefault();
    dispatch(fetchRemoveItemFromCart(props.item.rowId));
  };

  return (
    <div className="cart-item d-flex">
      <a
        href="/#"
        onClick={(event) => handleDeleteItem(event)}
        style={{ left: document.body.dir === "rtl" && "unset" }}
        className="remove-item-btn"
      >
        <i className="fa fa-times"></i>
      </a>
      <div className="cart-item-img-wrapper">
        <CartItemImage image={props.item.img} />
      </div>
      <div
        className="cart-item-details"
        style={{ marginRight: document.body.dir === "rtl" && "auto" }}
      >
        <div className="cart-item-product-name">
          {document.body.dir === "ltr" ? (
            <>
              {props.item.qty + " x "}
              <Link to={"/shop/" + props.item.slug}>{props.item.name}</Link>
            </>
          ) : (
            <>
              <Link to={"/shop/" + props.item.slug}>{props.item.name}</Link>
              {" x " + props.item.qty}
            </>
          )}
        </div>
        <div className="cart-item-product-price">
          {settingsState?.store_infos?.currency
            ? settingsState.store_infos.currency !== "DZD"
              ? t("currency", {
                  // price: number_format(props.item.price * props.item.qty),
                  price: number_format(
                    getWhsalePrice(
                      props.item?.qty,
                      props.item.price,
                      props.item?.special_price ? "" : props.item
                    ) * props.item.qty
                  ),
                  currency: settingsState.store_infos.currency,
                })
              : t("DA", {
                  // price: number_format(props.item.price * props.item.qty),
                  price: number_format(
                    getWhsalePrice(
                      props.item?.qty,
                      props.item.price,
                      props.item?.special_price ? "" : props.item
                    ) * props.item.qty
                  ),
                })
            : // : number_format(props.item.price * props.item.qty)}
              number_format(
                getWhsalePrice(
                  props.item?.qty,
                  props.item.price,
                  props.item?.special_price ? "" : props.item
                ) * props.item.qty
              )}
        </div>
        {Object.keys(props.item.options ?? {}).map((option) => (
          <div className="cart-item-product-property" key={`option-${option}`}>
            {option}: {props.item.options[option]}
          </div>
        ))}
      </div>
    </div>
  );
};
export default CartItem;
