import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SideCart from "../../components/cart/SideCart";
import { fetchLogoutAction } from "../../redux/actions/auth/userActions";
import "../../css/Layout/Header/topNav.css";

const TopNav = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeInfosState = useSelector((state) => state.storeInfos);
  const authState = useSelector((state) => state.auth.authenticated);
  const featuresState = useSelector((state) => state.features);

  let [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const [toggleSearch, setToggleSearch] = useState(false);

  useEffect(() => {
    setSearch(searchParams.has("search") ? searchParams.get("search") : "");
  }, [searchParams]);

  const handleSearch = () => {
    if (search.trim().length > 0) navigate("/shop?search=" + search);
  };

  const handleChangeLanguage = (event, lng) => {
    event.preventDefault();
    i18n.changeLanguage(lng);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    dispatch(fetchLogoutAction());
  };

  return (
    <div className="top-navigation-bar d-flex align-items-center">
      <div className="container">
        <nav className="nav w-100 justify-content-center justify-content-md-between ">
          <div className="top-navigation-left d-none d-md-flex">
            <span className="d-inline-flex align-items-center">
              {storeInfosState?.store_infos?.facebook && (
                <a href={storeInfosState.store_infos.facebook} target="_blank" className="social-link">
                  <span className="material-icons-outlined mx-2">facebook</span>
                </a>
              )}
              {storeInfosState?.store_infos?.instagram && (
                <a href={storeInfosState.store_infos.instagram} target="_blank" className="social-link">
                  <i className="fab fa-instagram"></i>
                </a>
              )}
            </span>
            {storeInfosState?.store_infos?.phone && (
              <>
                <span className="vertical-devider"></span>
                <div className="menu-contact-items">
                  <span className="d-inline-flex align-items-center">
                    <span className="material-icons-outlined mx-2">
                      support_agent
                    </span>
                    <span>
                      {t("call_us")}: {storeInfosState.store_infos.phone}
                    </span>
                  </span>
                </div>
              </>
            )}
          </div>
          {storeInfosState?.store_infos?.logo && (
            <Link to="/" className="d-none d-lg-block navbar-brand">
              <img
                src={storeInfosState.store_infos.logo}
                alt={storeInfosState?.store_infos?.name ?? ""}
              />
            </Link>
          )}
          <div className="nav top-navigation-right">
            <li className="nav-item dropdown custom-dropdown ">
              <a href="#" data-toggle="dropdown" className="nav-link">
                <span className="material-icons-outlined">person</span>
              </a>
              <ul className="dropdown-menu dropdown-menu-right">
                {!authState.user ? (
                  <>
                    <li className="dropdown-item">
                      <Link to="/login">{t("login")}</Link>
                    </li>
                    <li className="dropdown-item">
                      <Link to="/register">{t("register")}</Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="dropdown-item">
                      <Link to="/orders">{t("my_orders")}</Link>
                    </li>
                    {featuresState?.features?.includes("wishlist") &&
                      <li className="dropdown-item">
                        <Link to="/wishlist">{t("my_wishlist")}</Link>
                      </li>
                    }
                    <li className="dropdown-item">
                      <Link to="" onClick={handleLogout}>
                        {t("logout")}
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </li>
            <span className="vertical-devider"></span>
            <li className="nav-item search-input-toggler">
              <a href="#" className="nav-link" 
                onClick={(e) => {
                  e.preventDefault();
                  setToggleSearch(!toggleSearch)
                }}>
                <span className="material-icons-outlined">search</span>
              </a>
              <div className={`${toggleSearch ? "search-container search-container-show" : "search-container"}`}>
                <input
                  type="search"
                  value={search}
                  placeholder={t("search")}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") handleSearch();
                  }}
                />
                <button className="submit-search" onClick={handleSearch} aria-label="Search">
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </li>

            {featuresState?.features?.includes("add-to-cart") && (
              <>
                <span className="vertical-devider"></span>
                <SideCart />
              </>
            )}

            <span className="vertical-devider"></span>

            <li className="nav-item dropdown custom-dropdown language-selector">
              <Link
                to="nav-link align-items-center"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={(event) => event.preventDefault()}
              >
                {i18n.language === "ar" ? (
                  <>
                    <img
                      className="mx-2"
                      style={{ height: "12px" }}
                      src="./img/dz.svg"
                      alt={t("arabic")}
                    />
                    <span className=" d-none d-sm-inline-block d-md-none d-lg-inline-block">
                      {t("arabic")}
                    </span>
                  </>
                ) : i18n.language === "fr" ? (
                  <>
                    <img
                      className="mx-2"
                      style={{ height: "12px" }}
                      src="./img/fr.svg"
                      alt={t("french")}
                    />
                    <span className=" d-none d-sm-inline-block d-md-none d-lg-inline-block">
                      {t("french")}
                    </span>
                  </>
                ) : (
                  <>
                    <img
                      className="mx-2"
                      style={{ height: "12px" }}
                      src="./img/gb.svg"
                      alt={t("english")}
                    />
                    <span className=" d-none d-sm-inline-block d-md-none d-lg-inline-block">
                      {t("english")}
                    </span>
                  </>
                )}
              </Link>
              <ul className="dropdown-menu dropdown-menu-right">
                <li className="dropdown-item align-items-center">
                  <Link
                    to=""
                    className="align-items-center"
                    onClick={(event) => handleChangeLanguage(event, "en")}
                  >
                    <img
                      className="mx-2"
                      style={{ height: "12px" }}
                      src="./img/gb.svg"
                      alt={t("english")}
                      loading="lazy"
                    />{" "}
                    {t("english")}
                  </Link>
                </li>
                <li className="dropdown-item align-items-center">
                  <Link
                    to=""
                    className="align-items-center"
                    onClick={(event) => handleChangeLanguage(event, "fr")}
                  >
                    <img
                      className="mx-2"
                      style={{ height: "12px" }}
                      src="./img/fr.svg"
                      alt={t("french")}
                      loading="lazy"
                    />{" "}
                    {t("french")}
                  </Link>
                </li>
                <li className="dropdown-item ">
                  <Link
                    to=""
                    className="align-items-center"
                    onClick={(event) => handleChangeLanguage(event, "ar")}
                  >
                    <img
                      className="mx-2"
                      style={{ height: "12px" }}
                      src="./img/dz.svg"
                      alt={t("arabic")}
                      loading="lazy"
                    />{" "}
                    {t("arabic")}
                  </Link>
                </li>
              </ul>
            </li>
          </div>
        </nav>
      </div>
    </div>
  );
};
export default TopNav;
