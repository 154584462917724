import React, { useState } from "react";
import ImageLoading from "../ImageLoading";

const SliderImage = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <link
        rel="preload"
        as="image"
        href={props.image}
        imageSrcSet={`${props.image}?w=1440&h=1000 1440w,${props.image}?w=1024&h=502 1024w,${props.image}?w=768&h=377 768w,${props.image}?w=425&h=208 425w,`}
        imageSizes="
            (max-width: 425px) 425px,
            (max-width: 768px) 768px,
            (max-width: 1024px) 1024px,
            1440px"
        onLoad={() => setLoaded(true)}
        style={{ display: "none" }}
        alt={props.image}
      />
      {loaded ? (
        <img
          srcSet={`${props.image}?w=1440&h=1000 1440w,${props.image}?w=1024&h=502 1024w,${props.image}?w=768&h=377 768w,${props.image}?w=425&h=208 425w,`}
          sizes="
            (max-width: 425px) 425px,
            (max-width: 768px) 768px,
            (max-width: 1024px) 1024px,
            1440px"
          alt={props.image}
        />
      ) : (
        <ImageLoading width={"100%"} height={"300px"} />
      )}
    </>
  );
};

export default SliderImage;
