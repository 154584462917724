import { combineReducers } from "redux";

import bestSelling from "./bestSellingProductsReducer";
import featured from "./featuredProductsReducer";
import topRated from "./topRatedProductsReducer";
import newest from "./newProductsReducer";
import shop from "./shopReducer";
import productDetails from "./productDetailsReducer";
import related from "./relatedProductsReducer";
import ratings from "./ratings/productRatingsReducer";
import addRating from "./ratings/addProductRatingReducer";
import flashSales from "./flashSalesReducer";

const rootProductsReducer = combineReducers({
  newest,
  featured,
  bestSelling,
  flashSales,
  topRated,
  shop,
  productDetails,
  related,
  ratings,
  addRating
});
export default rootProductsReducer;
