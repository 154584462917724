import {
    FEES_REQUEST,
    FEES_SUCCESS,
    FEES_FAIL,
}from "../../../shared/constants";
import { api } from "../../../shared/shared";


const fetchFeesBegin = () => ({
    type: FEES_REQUEST,
});

const fetchFeesSuccess = (fees) => ({
    type: FEES_SUCCESS,
    payload: { fees },
});
 const fetchFeesFailure = (error) => ({
    type: FEES_FAIL, 
    payload: { error },
});

const fetchFeesAction = (wilaya) => (dispatch) =>  {
        dispatch(fetchFeesBegin()); 
        api.get("/api/delivery-fees").then((res) => {
            if (res.data.success) dispatch(fetchFeesSuccess(res.data.data));
            else dispatch(fetchFeesFailure(res.data.message));
            
        }).catch(error => {
            return dispatch(fetchFeesFailure(error.message));
        })
    };

export default fetchFeesAction;