import {
  SHOP_REQUEST,
  SHOP_SUCCESS,
  SHOP_FAIL,
} from "../../../shared/constants";

import { api } from "../../../shared/shared";

export const fetchShopBegin = () => ({
  type: SHOP_REQUEST,
});

export const fetchShopSuccess = (data) => ({
  type: SHOP_SUCCESS,
  payload: { data },
});

export const fetchShopFailure = (error) => ({
  type: SHOP_FAIL,
  payload: { error },
});

const fetchShopAction = (category, search) => {
  return (dispatch) => {
    dispatch(fetchShopBegin());
    let query = category ? "/api/categories/" + category : "/api/products/shop";
    api
      .get(query + search)
      .then((res) =>
        res.data.success
          ? dispatch(fetchShopSuccess(res.data))
          : dispatch(fetchShopFailure(res.data.message))
      )
      .catch((error) => dispatch(fetchShopFailure(error.message)));
  };
};

export default fetchShopAction;
