import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProductsCarousel from "../products/ProductsCarousel";
import fetchShopAction from "../../redux/actions/products/shopAction";
import ImageLoading from "../ImageLoading";
import "../../css/Home/productsByCategory.css";

const CategorySection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const featuredCategoriesState = useSelector(
    (state) => state.categories.featured
  );
  const categoryProducts = useSelector((state) => state.products.shop);
  const [selectedCategorySlug, setSelectedCategorySlug] = useState("");

  const handleSlug = (slug) => setSelectedCategorySlug(slug);

  useEffect(() => {
    selectedCategorySlug === "" &&
      featuredCategoriesState?.items?.length > 0 &&
      setSelectedCategorySlug(featuredCategoriesState.items[0].slug);
    selectedCategorySlug !== "" &&
      dispatch(fetchShopAction(selectedCategorySlug, ""));
  }, [selectedCategorySlug, featuredCategoriesState?.items?.length]);

  useEffect(() => {
    selectedCategorySlug !== "" &&
      dispatch(fetchShopAction(selectedCategorySlug, ""));
  }, [selectedCategorySlug]);

  return featuredCategoriesState?.loading ? (
    <ImageLoading width={"100%"} aspectRatio={4} marginTop={"40px"} />
  ) : (
    featuredCategoriesState?.items?.length > 0 && (
      <section className="product-by-category-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex w-100 top-rated-section-header">
                <h3 className="text-uppercase d-none d-md-block">
                  {t("products_categories")}
                </h3>
                {featuredCategoriesState?.items?.length > 0 && (
                  <ul
                    className="nav nav-pills ml-auto mr-auto product-by-category-nav-pills"
                    id="product-by-category-nav-pills"
                    role="tablist"
                  >
                    {featuredCategoriesState.items
                      .slice(0, 3)
                      .map((category, index) => (
                        <li className="nav-item" key={category.slug} role="tab">
                          <a
                            className={
                              category.slug === selectedCategorySlug
                                ? "nav-link active"
                                : "nav-link"
                            }
                            style={{ marginInlineEnd: "20px" }}
                            id={category.slug + "-toggler"}
                            data-toggle="pill"
                            href={"#" + category.slug + "-tab"}
                            role="tab"
                            aria-controls={category.slug + "-tab"}
                            aria-selected={index === 0 ? "true" : "false"}
                            onClick={() => handleSlug(category.slug)}
                          >
                            {category.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id={selectedCategorySlug + "-tab"}
                  role="tabpanel"
                  aria-labelledby={selectedCategorySlug + "-toggler"}
                >
                  <div className="row">
                    {categoryProducts?.items?.length > 0 ? (
                      <ProductsCarousel
                        className="new-products-slider"
                        componentKey={`${selectedCategorySlug}-products-slider`}
                        products={categoryProducts}
                      />
                    ) : (
                      <div className="col-md-12 text-center my-3">
                        <i
                          className="fas fa-exclamation-triangle fa-5x"
                          style={{ marginBottom: "15px" }}
                        ></i>
                        <h1>{t("no_product_found")}</h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  );
};

export default CategorySection;
