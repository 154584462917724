import React, { useState } from "react";
import ImageLoading from "../ImageLoading";

const WishlistItemImage = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <img
        src={props.image}
        srcSet={`${props.image}?w=120&h=128 1440w,${props.image}?w=120&h=128 1024w,${props.image}?w=120&h=128 768w,${props.image}?w=120&h=128 425w,`}
        sizes="
              (max-width: 425px) 120px,
              (max-width: 768px) 120px,
              (max-width: 1024px) 120px,
              120px"
        onLoad={() => setLoaded(true)}
        style={{ display: "none" }}
        alt={props.image}
      />
      {loaded ? (
        <img
          srcSet={`${props.image}?w=120&h=150 1440w,${props.image}?w=120&h=150 1024w,${props.image}?w=120&h=150 768w,${props.image}?w=120&h=150 425w,`}
          sizes="
                (max-width: 425px) 120px,
                (max-width: 768px) 120px,
                (max-width: 1024px) 120px,
                120px"
          alt={props.image}
        />
      ) : (
        <ImageLoading width={"100px"} height={"128px"} />
      )}
    </>
  );
};

export default WishlistItemImage;
