import React from 'react'
import ImageLoading from '../ImageLoading';

const ProductLoading = (props) => {
  return (
    <div className='border p-3'>
      <div className='d-flex justify-content-between mb-3'>
        <ImageLoading width={"52px"} aspectRatio={'52/30'} />
        <ImageLoading width={"52px"} aspectRatio={'52/30'} />
      </div>
      <div className='mb-3'>
        <ImageLoading width={"100%"} aspectRatio={1} />
      </div>
      <div className='mb-3'>
        <div className='d-flex justify-content-center  mb-2'>
          <ImageLoading width={"90%"} aspectRatio={'238/25'} />
        </div>
        <div className='d-flex justify-content-center '>
          <ImageLoading width={"60%"} aspectRatio={'238/25'} />
        </div>
      </div>
      <hr />
      <div className='d-flex justify-content-center '>
        <ImageLoading width={"80%"} aspectRatio={'238/52'} />
      </div>
    </div>
  )
}
export default ProductLoading;