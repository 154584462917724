import React from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "./modalContext";

const MyModal = () => {
  let { modalContent, modal } = React.useContext(ModalContext);
  if (modal) {
    return ReactDOM.createPortal(
      <>
        {modalContent}
        <div className="modal-backdrop fade show"></div>
      </>,
      document.querySelector("#modal-root")
    );
  } else return null;
};

export default MyModal;
