import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { number_format } from "../../shared/shared";
import { useSelector } from "react-redux";

function WholesaleSelect({ wh_offers }) {
  const { t } = useTranslation();
  const settingsState = useSelector((state) => state.storeInfos);

  const renderPrice = useCallback(
    (currency, price) => {
      const formattedPrice = number_format(price);

      if (!currency) {
        return formattedPrice;
      }

      if (currency === "DZD") {
        return t("DA", { price: formattedPrice });
      }

      return t("currency", { price: formattedPrice, currency });
    },
    [settingsState]
  );

  return (
    <div className="custom-select-wrapper col-lg-12 p-0 mt-3">
      <select className="custom-select wholesale-select font-weight-bold font-bold">
        <option value={-1} selected>
          {t("Wholesale Offers")}
        </option>
        {wh_offers?.map((offer, idx) => (
          <option key={offer + idx} disabled className="font-weight-bold">
            {`${t("from")} `} {offer.start_quantity || offer.min_quantity}{" "}
            {offer?.max_quantity === null || offer?.end_quantity === null
              ? `${t("And More")} `
              : `${t("to")} ${offer?.end_quantity || offer?.max_quantity} `} -{" "}
            {renderPrice(settingsState.store_infos.currency, offer.price)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default React.memo(WholesaleSelect);
