import React from "react";
import OwlCarousel from "react-owl-carousel";
import ProductItem from "./ProductItem";
import ProductLoading from "./ProductLoading";

const ProductsCarousel = (props) => {

  return props.products &&
    props.products.items &&
    props.products.items.length > 0 ? (
    <OwlCarousel
      className={`col-md-12 ${props.className} owl-carousel`}
      autoplay
      margin={15}
      dots={false}
      lazyLoad={true}
      rtlClass={`${document.body.dir === "rtl" ? "owl-rtl" : ""}`}
      responsive={{
        0: { items: 1 },
        450: { items: 2 },
        620: { items: 3 },
        992: { items: 4 },
      }}
      key={props.componentKey}
    >
      {props.products.items.map((product) => (
        <div className="item" key={`${product.id}-${props.componentKey}`}>
          <ProductItem product={product} />
        </div>
      ))}
    </OwlCarousel>
  ) : (
    <OwlCarousel
      className={`col-md-12 ${props.className} owl-carousel`}
      loop={false}
      autoplay
      margin={15}
      dots={false}
      rtlClass={`${document.body.dir === "rtl" ? "owl-rtl" : ""}`}
      nav
      responsive={{
        0: { items: 1 },
        450: { items: 2 },
        620: { items: 3 },
        992: { items: 4 },
      }}
      key={`${props.componentKey}-loading`}
    >
      {<ProductLoading />}
    </OwlCarousel>
  );
};

export default ProductsCarousel;
