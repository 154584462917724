import {
    FLASH_SALES_REQUEST,
    FLASH_SALES_SUCCESS,
    FLASH_SALES_FAIL,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";
  
const fetchFlashSalesBegin = () => ({
    type: FLASH_SALES_REQUEST,
});
  
const fetchFlashSalesSuccess = (products) => ({
    type: FLASH_SALES_SUCCESS,
    payload: { products },
});
  
const fetchFlashSalesFailure = (error) => ({
    type: FLASH_SALES_FAIL,
    payload: { error },
});
  
const fetchFlashSalesProductsAction = (take) => (dispatch) => {
    dispatch(fetchFlashSalesBegin());
    api
      .get("api/products/flash-sales")
      .then((res) =>
        res.data.success
          ? dispatch(fetchFlashSalesSuccess(res.data.data))
          : dispatch(fetchFlashSalesFailure(res.data.message))
      )
      .catch((error) => dispatch(fetchFlashSalesFailure(error.message)));
};
  
export default fetchFlashSalesProductsAction;
  