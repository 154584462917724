import {
  ADD_TO_WISHLIST_REQUEST,
  ADD_TO_WISHLIST_SUCCESS,
  ADD_TO_WISHLIST_FAIL,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";
import fetchWishlistAction from "./wishlistActions";

const fetchAddToWishlistBegin = () => ({
  type: ADD_TO_WISHLIST_REQUEST,
});

const fetchAddToWishlistSuccess = (message) => ({
  type: ADD_TO_WISHLIST_SUCCESS,
  payload: { message },
});

const fetchAddToWishlistFailure = (error) => ({
  type: ADD_TO_WISHLIST_FAIL,
  payload: { error },
});

export const fetchAddToWishlistAction = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    {
      dispatch(fetchAddToWishlistBegin());
      api
        .post("/api/wishlist", {
          id: id,
        })
        .then((res) => {
          if (res.data.success) {
            dispatch(fetchAddToWishlistSuccess(res.data.message));
            dispatch(fetchWishlistAction());
            resolve(res.data.message);
          } else {
            dispatch(fetchAddToWishlistFailure(res.data.message));
            reject(res.data.message);
          }
        })
        .catch((error) => {
          dispatch(fetchAddToWishlistFailure(error.message));
          reject(error.message);
        });
    }
  });
