import {
  RELATED_PRODUCTS_FAIL,
  RELATED_PRODUCTS_REQUEST,
  RELATED_PRODUCTS_SUCCESS,
} from "../../../shared/constants";

import { api } from "../../../shared/shared";

const fetchRelatedBegin = () => ({
  type: RELATED_PRODUCTS_REQUEST,
});

const fetchRelatedSuccess = (products) => ({
  type: RELATED_PRODUCTS_SUCCESS,
  payload: { products },
});

const fetchRelatedFailure = (error) => ({
  type: RELATED_PRODUCTS_FAIL,
  payload: { error },
});

const fetchRelatedProductsAction = (id) => (dispatch) => {
  dispatch(fetchRelatedBegin());
  api
    .get(`api/products/${id}/related`)
    .then((res) =>
      res.data.success
        ? dispatch(fetchRelatedSuccess(res.data.data))
        : dispatch(fetchRelatedFailure(res.data.message))
    )
    .catch((error) => dispatch(fetchRelatedFailure(error.message)));
};

export default fetchRelatedProductsAction;
