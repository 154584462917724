import React, { useState } from "react";
import { useSelector } from "react-redux";
import ImageLoading from "../ImageLoading";

const ProductImage = (props) => {
  const productDetailsState = useSelector((state) => state.products.productDetails);
  const [loaded, setLoaded] = useState(false);

  return (
    <div style={{ width: "100%" }}>
      {!productDetailsState?.product?.loading &&
      props.selectedImage &&
      props.selectedImage !== "" ? (
        <>
          <link
            rel="preload"
            as="image"
            href={props.selectedImage}
            imageSrcSet={`${props.selectedImage}?w=650&h=600 1440w,${props.selectedImage}?w=440&h=440 1024w,${props.selectedImage}?w=360&h=360 768w,${props.selectedImage}?w=390&h=390 425w,`}
            imageSizes="
                (max-width: 425px) 390px,
                (max-width: 768px) 360px,
                (max-width: 1024px) 440px,
                650px"
            onLoad={() => setLoaded(true)}
            style={{ display: "none", width: "100%" }}
            alt={productDetailsState.product.name}
          />
          {loaded ? (
            <>
              <div className="product-details-product-img-wrapper">
                <div
                  style={{
                    overflow: "hidden",
                    height: "auto",
                    maxHeight: "600px",
                  }}
                >
                  <img
                    style={{
                      objectFit: "contain",
                      maxHeight: "600px",
                      width: "100%",
                    }}
                    srcSet={`${props.selectedImage}?w=800&h=800 1440w,${props.selectedImage}?w=700&h=700 1024w,${props.selectedImage}?w=600&h=600 768w,${props.selectedImage}?w=390&h=390 425w,`}
                    sizes="
                        (max-width: 425px) 390px,
                        (max-width: 768px) 600px,
                        (max-width: 1024px) 700px,
                        800px"
                    alt={productDetailsState.product.name}
                  />
                </div>
              </div>
            </>
          ) : (
            <ImageLoading aspectRatio={"1"} width={"100%"} />
          )}
        </>
      ) : (
        <ImageLoading aspectRatio={"1"} width={"100%"} />
      )}
    </div>
  );
};

export default ProductImage;
