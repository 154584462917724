import React, { useState } from "react";
import ImageLoading from "../ImageLoading";

const GalleryImage = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <img
        srcSet={`${props.image}?w=125&h=130 1440w,${props.image}?w=100&h=100 1024w,${props.image}?w=100&h=100 768w,${props.image}?w=100&h=100 425w,`}
        sizes="
            (max-width: 425px) 100px,
            (max-width: 768px) 100px,
            (max-width: 1024px) 100px,
            125px"
        onLoad={() => setLoaded(true)}
        style={{ display: "none" }}
        alt={props.image}
      />
      {loaded ? (
        <img
          srcSet={`${props.image}?w=200&h=200 1440w,${props.image}?w=200&h=200 1024w,${props.image}?w=200&h=200 768w,${props.image}?w=200&h=200 425w,`}
          sizes="
            (max-width: 425px) 150px,
            (max-width: 768px) 200px,
            (max-width: 1024px) 200px,
            200px"
          alt={props.image}
        />
      ) : (
        <ImageLoading width={"130px"} height={"110px"} />
      )}
    </>
  );
};

export default GalleryImage;
