import React, { useState } from "react";
import ImageLoading from "../ImageLoading";

const CategoryImage = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <img
        srcSet={`${props.image}?w=380&h=250 1440w,${props.image}?w=290&h=200 1024w,${props.image}?w=250&h=160 768w,${props.image}?w=390&h=260 425w,`}
        sizes="
            (max-width: 425px) 390px,
            (max-width: 768px) 250px,
            (max-width: 1024px) 290px,
            380px"
        onLoad={() => setLoaded(true)}
        style={{ display: "none" }}
        alt={props.image}
      />
      {loaded ? (
        <img
          srcSet={`${props.image}?w=450&h=450 1440w,${props.image}?w=4000&h=400 1024w,${props.image}?w=350&h=350 768w,${props.image}?w=390&h=260 425w,`}
          sizes="
            (max-width: 425px) 390px,
            (max-width: 768px) 350px,
            (max-width: 1024px) 400px,
            450px"
          alt={props.image}
        />
      ) : (
        <ImageLoading width={"100%"} height={"250px"} />
      )}
    </>
  );
};

export default CategoryImage;
