import {
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAIL,
} from "../../shared/constants";
import { api } from "../../shared/shared";

import { fetchGetCart } from "./cart/getCartActions";
import fetchWishlistAction from "./wishlist/wishlistActions";

const fetchCheckoutBegin = () => ({
  type: CHECKOUT_REQUEST,
});

const fetchCheckoutSuccess = (message) => ({
  type: CHECKOUT_SUCCESS,
  payload: { message },
});

const fetchCheckoutFailure = (statusCode = 200, message, errors = null) => ({
  type: CHECKOUT_FAIL,
  payload: { statusCode, message, errors },
});

export const fetchCheckout = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchCheckoutBegin());
    api
      .post("api/checkout", data)
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchCheckoutSuccess(res.data.message));
          dispatch(fetchGetCart());
          dispatch(fetchWishlistAction());
          /* if(res.data.chargily_link !== null)
            window.location.replace(res.data.chargily_link) */
          resolve(res.data.message);
        } else {
          dispatch(fetchCheckoutFailure(200, res.data.message));
          reject(res.data.message);
        }
      })
      .catch((error) => {
        dispatch(
          fetchCheckoutFailure(
            error.response.status,
            error.response.data.message,
            error.response.data.errors
          )
        );
        reject(error.response.data.message);
      });
  });
