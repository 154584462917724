import React, {
  Suspense,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AlertModal from "../components/AlertModal";
import { ModalContext } from "../components/modal/modalContext";
import { compareObjects, number_format } from "../shared/shared";
import parse from "html-react-parser";
import { fetchAddToCartAction } from "../redux/actions/cart/addToCartActions";
import { fetchAddToWishlistAction } from "../redux/actions/wishlist/addActions";
import fetchProductRatingsActions from "../redux/actions/products/ratings/productRatingsActions";
import fetchRelatedProductsAction from "../redux/actions/products/relatedProductsAction";
import fetchProductDetails from "../redux/actions/products/productDetailsActions";
import OwlCarousel from "react-owl-carousel";
import ImageLoading from "../components/ImageLoading";
import ProductsCarousel from "../components/products/ProductsCarousel";
import OrderProduct from "../components/products/OrderProduct";
import { useRef } from "react";
import ReactPixel from "react-facebook-pixel";
import { NamePriceRating } from "../components/products/NamePriceRating";
import "../css/product.css";
import "../css/Product/relatedCarousel.css";
import ProductImage from "../components/products/ProductImage";
import GalleryImage from "../components/products/GalleryImage";
import Wholesale from "../components/products/Wholesale";
import useWholesale from "../hooks/useWholesale";

const RatingsSection = lazy(() =>
  import("../components/products/RatingsSection")
);
const ShareButtons = lazy(() => import("../components/products/ShareButtons"));
const CallNow = lazy(() => import("../components/CallNow"));

const ProductPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productSlug = useParams();
  const navigate = useNavigate();
  const [fees, setFees] = useState(0);
  const { handleModal } = useContext(ModalContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(searchParams.get("page"));
  const [quantity, setQuantity] = useState(1);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [minQuantity, setMinQuantity] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [nbOptionsForm, setNbOptionsForm] = useState(0);
  const [optionsForm, setOptionsForm] = useState({});
  const isMounted = useRef(false);

  const addToCartState = useSelector((state) => state.cart.addToCart);
  const authState = useSelector((state) => state.auth.authenticated);
  const productDetailsState = useSelector(
    (state) => state.products.productDetails
  );
  const relatedProductsState = useSelector((state) => state.products.related);
  const productRatingsState = useSelector((state) => state.products.ratings);
  const featuresState = useSelector((state) => state.features);
  const settingsState = useSelector((state) => state.storeInfos);

  const { getWhsalePrice } = useWholesale(productDetailsState?.product);

  const handleQtyChange = (e) => {
    let { value, min, max } = e.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setQuantity(value);
  };

  const handleIncrementQty = () =>
    quantity < maxQuantity && setQuantity(parseInt(quantity) + 1);
  const handleDecrementQty = () =>
    quantity > minQuantity && setQuantity(parseInt(quantity) - 1);

  const handlePageClick = (event) => setPage(event.selected + 1);

  const handleFees = (fees) => setFees(fees);

  const handleOptionsFormChanges = (event) => {
    let data = { ...optionsForm };
    data[event.target.name] = event.target.value;
    setOptionsForm(data);
    let option = productDetailsState.product.options.find((option) => {
      return compareObjects(data, option.properties);
    });
    if (option) {
      setSelectedOption(option);
      setMaxQuantity(option.stock);
      setMinQuantity(option.order_min_quantity);
    } else {
      setSelectedOption(null);
      setMaxQuantity(0);
    }
  };

  const handleAddToCart = () => {
    if (quantity <= maxQuantity && quantity >= minQuantity) {
      let data = {
        productId: productDetailsState.product.id,
        quantity: quantity,
        model: productDetailsState.product.model,
      };
      if (selectedOption) {
        data["productId"] = selectedOption.id;
        data["model"] = selectedOption.model;
      }
      dispatch(fetchAddToCartAction(data))
        .then((res) => {
          ReactPixel.track("AddToCart", {
            content_type: "product",
            content_ids: [data["productId"]],
            contents: [
              {
                id: data["productId"],
                price: selectedOption
                  ? selectedOption.special_price ?? selectedOption.price
                  : productDetailsState.product.special_price ??
                    productDetailsState.product.price,
                quantity: data["quantity"],
                name: productDetailsState.product.name,
              },
            ],
          });
          handleModal(
            <AlertModal
              variant="success"
              title={t("add to cart")}
              message={t("added_successfully_to_cart")}
            />
          );
        })
        .catch((err) => {
          handleModal(
            <AlertModal
              variant="danger"
              title={t("add to cart")}
              message={t("failAddedToCart")}
            />
          );
        });
    }
  };

  const handleWishlist = (id) => {
    if (authState.user) {
      dispatch(fetchAddToWishlistAction(id))
        .then((res) => {
          ReactPixel.track("AddToWishlist", {
            content_type: "product",
            contents: [
              {
                id: id,
                price: productDetailsState.product.price,
                name: productDetailsState.product.name,
              },
            ],
          });
          handleModal(
            <AlertModal
              variant="success"
              title={t("add_to_wishlist")}
              message={t("Product added to the wishlist successfully")}
            />
          );
        })
        .catch((err) =>
          handleModal(
            <AlertModal
              variant="danger"
              title={t("add_to_wishlist")}
              message={t("Couldn't add the product to the wishlist")}
            />
          )
        );
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    dispatch(fetchProductDetails(productSlug["productSlug"]))
      .then((product) => setSelectedImage(product.image))
      .catch((err) => navigate("/shop"));
    dispatch(fetchRelatedProductsAction(productSlug["productSlug"]));
  }, [productSlug["productSlug"]]);

  useEffect(() => {
    if (featuresState?.features?.includes("reviews"))
      dispatch(fetchProductRatingsActions(productSlug["productSlug"], page));
  }, [
    productSlug["productSlug"],
    page,
    featuresState?.features?.includes("reviews"),
  ]);

  useEffect(() => {
    if (productDetailsState?.product) {
      if (
        productDetailsState?.product?.has_options &&
        Object.keys(productDetailsState.product.available_options ?? {})
          .length > 0
      ) {
        let data = {};
        let nb = 0;
        Object.keys(productDetailsState.product.available_options ?? {}).map(
          (option) => {
            data[option] = null;
            nb += 1;
          }
        );
        setOptionsForm(data);
        setNbOptionsForm(nb);
      }
      setMaxQuantity(productDetailsState.product.stock);
      setMinQuantity(productDetailsState.product.order_min_quantity);
    }
  }, [productDetailsState?.product]);

  useEffect(() => {
    if (isMounted.current) {
      selectedOption
        ? setSelectedImage(selectedOption.image)
        : setSelectedImage(productDetailsState.product.image);
    } else isMounted.current = true;
  }, [selectedOption]);

  useEffect(() => {
    if (productDetailsState?.product) {
      ReactPixel.track("ViewContent", {
        content_name: "Product Page : " + productDetailsState.product.name,
      });
    }
  }, [productDetailsState?.product?.name]);

  let arabic = /[\u0600-\u06FF]/;

  return (
    <>
      <div className="custom-breadcrumb">
        <div className="container">
          <ol className="breadcrumb ">
            <li className="breadcrumb-item">
              <Link to="/">{t("home")}</Link>
            </li>
            {document.body.dir === "rtl" && (
              <span className="breadcrumb-separator">/</span>
            )}
            <li className="breadcrumb-item">
              <Link to="/shop">{t("shop")}</Link>
            </li>
            {document.body.dir === "rtl" && (
              <span className="breadcrumb-separator">/</span>
            )}
            <li className="breadcrumb-item active">
              {productDetailsState.product.name}
            </li>
          </ol>
        </div>
      </div>

      <section className="product-details-section">
        <div className="container">
          <div className="row">
            {!productDetailsState.loading && productDetailsState?.product ? (
              <div className="col-12">
                <div className="product-name-price mb-3">
                  <NamePriceRating
                    selectedOption={selectedOption}
                    name={productDetailsState.product.name}
                    short_description={
                      productDetailsState.product.short_description
                    }
                    price={productDetailsState.product.price}
                    special_price={productDetailsState.product.special_price}
                    fees={fees}
                    product={productDetailsState.product}
                    quantity={quantity}
                  />
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="product-img-side"
                      style={{ position: "sticky", top: "64px" }}
                    >
                      <div
                        className="product-details-product-img-wrapper"
                        onClick={() =>
                          setSelectedImage(productDetailsState.product.image)
                        }
                      >
                        <ProductImage selectedImage={selectedImage} />
                        {featuresState?.features?.includes("wishlist") && (
                          <a
                            onClick={() =>
                              handleWishlist(productDetailsState.product.id)
                            }
                            className="add-wish-list"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={30}
                              height={30}
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M10.517 17.3413C10.2337 17.4413 9.76699 17.4413 9.48366 17.3413C7.06699 16.5163 1.66699 13.0747 1.66699 7.24134C1.66699 4.66634 3.74199 2.58301 6.30033 2.58301C7.81699 2.58301 9.15866 3.31634 10.0003 4.44967C10.842 3.31634 12.192 2.58301 13.7003 2.58301C16.2587 2.58301 18.3337 4.66634 18.3337 7.24134C18.3337 13.0747 12.9337 16.5163 10.517 17.3413Z"
                                stroke="#FFFDFA"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </a>
                        )}
                      </div>

                      {!productDetailsState?.loading &&
                      productDetailsState?.product?.media &&
                      productDetailsState?.product?.media?.length > 0 &&
                      featuresState?.features?.includes("gallery") ? (
                        <OwlCarousel
                          className="owl-carousel product-details-slider"
                          loop={false}
                          nav
                          autoplay
                          margin={15}
                          rtlClass={`${
                            document.body.dir === "rtl" ? "owl-rtl" : ""
                          }`}
                          items={4}
                          lazyLoad={true}
                          autoWidth={false}
                          key={"product-details-slider"}
                        >
                          <div className="slider-wrapper item"
                            onClick={() => setSelectedImage(productDetailsState.product.image)}
                          >
                            <GalleryImage image={productDetailsState.product.image} />
                          </div>
                          {productDetailsState.product.media.map((item, i) => (
                            <div
                              className="slider-wrapper item"
                              key={item.url + i}
                              onClick={() => setSelectedImage(item.url)}
                            >
                              <div className="item">
                                <div className="product-details-slider-thumb mx-auto">
                                  <GalleryImage image={item.url} />
                                </div>
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      ) : (
                        productDetailsState?.loading &&
                        featuresState?.features?.includes("gallery") && (
                          <OwlCarousel
                            className="owl-carousel product-details-slider"
                            loop={false}
                            nav
                            autoplay
                            margin={15}
                            items={4}
                            rtlClass={`${
                              document.body.dir === "rtl" ? "owl-rtl" : ""
                            }`}
                            autoWidth={false}
                            key={"product-details-slider-loader"}
                          >
                            {[...Array(4)].map((item, i) => (
                              <div
                                className="slider-wrapper item"
                                key={`gallery-item-loader-${i}`}
                              >
                                <div className="item">
                                  <div className="product-details-slider-thumb">
                                    <ImageLoading
                                      width={"129px"}
                                      aspectRatio={1}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </OwlCarousel>
                        )
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="product-name-price-mobile mb-2">
                      <NamePriceRating
                        selectedOption={selectedOption}
                        name={productDetailsState.product.name}
                        short_description={
                          productDetailsState.product.short_description
                        }
                        price={productDetailsState.product.price}
                        special_price={
                          productDetailsState.product.special_price
                        }
                        fees={fees}
                        product={productDetailsState.product}
                        quantity={quantity}
                      />
                    </div>
                    {maxQuantity > 0 ? (
                      <div
                        className={`mt-3 ${
                          document.body.dir === "rtl" && "text-right"
                        }`}
                      >
                        <i
                          className="fa fa-check mr-1"
                          style={{ color: "#2ea76f", fontSize: 12 }}
                        />{" "}
                        {t("available")}
                      </div>
                    ) : (
                      <div
                        className={`mt-3 ${
                          document.body.dir === "rtl" && "text-right"
                        }`}
                      >
                        <i
                          className="fa fa-times mr-1"
                          style={{ color: "#d10024", fontSize: 12 }}
                        />{" "}
                        {t("not_available")}
                      </div>
                    )}
                    <div className="product-details-product-properties mb-2">
                      {productDetailsState?.product?.has_options &&
                        nbOptionsForm > 0 &&
                        Object.keys(optionsForm).length > 0 && (
                          <>
                            {Object.keys(optionsForm).map((option, index) => (
                              <div
                                className="custom-select-wrapper mb-2"
                                key={"option-" + index}
                              >
                                <label>{option}:</label>
                                <select
                                  onChange={(event) =>
                                    handleOptionsFormChanges(event)
                                  }
                                  name={option}
                                  className="custom-select"
                                >
                                  <option key={option + "00"} value={""}>
                                    {t("selectAProperty")}
                                  </option>
                                  {productDetailsState?.product
                                    ?.available_options &&
                                    productDetailsState?.product
                                      ?.available_options[option] &&
                                    productDetailsState.product.available_options[
                                      option
                                    ].map((value) => (
                                      <option
                                        key={option + value}
                                        value={value}
                                      >
                                        {value}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            ))}
                            <hr />
                          </>
                        )}
                    </div>
                    {!featuresState?.features?.includes("buy-now") && (
                      <>
                        {featuresState?.features?.includes("wholesale") &&
                          (productDetailsState?.product?.wholesale?.length >
                            0 ||
                            selectedOption?.wholesale?.length > 0) && (
                            <Wholesale
                              whItems={
                                selectedOption
                                  ? selectedOption
                                  : productDetailsState?.product
                              }
                            />
                          )}
                        <div className="d-flex flex-md-row flex-column align-items-center">
                          <div className="quantity-selector ml-5 my-2">
                            <div className="d-inline-block">
                              <button
                                type="button"
                                aria-label="Decrement quantity"
                                onClick={handleDecrementQty}
                              >
                                <i className="fa fa-minus" />
                              </button>
                            </div>
                            <input
                              type="number"
                              min={minQuantity}
                              max={maxQuantity}
                              value={quantity}
                              onChange={handleQtyChange}
                            />
                            <div className="d-inline-block">
                              <button
                                type="button"
                                className="plus-btn"
                                aria-label="Increment quantity"
                                onClick={handleIncrementQty}
                              >
                                <i className="fa fa-plus" />
                              </button>
                            </div>
                          </div>
                          {/* Order minimum quanity */}
                          <p
                            className={`font-weight-bold p-2 m-0 ${
                              document.body.dir === "rtl" && "text-right"
                            }`}
                          >
                            {t("min quantity", {
                              qty: selectedOption
                                ? selectedOption?.order_min_quantity
                                : productDetailsState?.product
                                    ?.order_min_quantity,
                            })}
                          </p>
                        </div>
                        <div
                          className={`custom-input ${
                            document.body.dir === "rtl"
                              ? "text-right"
                              : "text-left"
                          }`}
                        >
                          {(((quantity === "" ||
                            quantity < minQuantity ||
                            quantity > maxQuantity) &&
                            productDetailsState.product.has_options &&
                            selectedOption) ||
                            ((quantity === "" ||
                              quantity < minQuantity ||
                              quantity > maxQuantity) &&
                              !productDetailsState.product.has_options)) &&
                            maxQuantity !== 0 && (
                              <span className="custom-input-error-message">
                                {t("validations.between", {
                                  attribute: t("quantity"),
                                  min: minQuantity,
                                  max: maxQuantity,
                                })}
                              </span>
                            )}
                        </div>
                        <div className="d-flex align-items-center m-2 quantity-section">
                          {settingsState?.store_infos?.currency ? (
                            settingsState.store_infos.currency !== "DZD" ? (
                              <>
                                <div className="quantity-price">
                                  {t("currency", {
                                    price: number_format(
                                      (selectedOption
                                        ? getWhsalePrice(
                                            quantity,
                                            selectedOption.price,
                                            selectedOption
                                          )
                                        : getWhsalePrice(
                                            quantity,
                                            productDetailsState.product.price
                                          )) * quantity
                                    ),
                                    currency:
                                      settingsState.store_infos.currency,
                                  })}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="quantity-price">
                                  {t("DA", {
                                    price: number_format(
                                      (selectedOption
                                        ? getWhsalePrice(
                                            quantity,
                                            selectedOption.price,
                                            selectedOption
                                          )
                                        : getWhsalePrice(
                                            quantity,
                                            productDetailsState.product.price
                                          )) * quantity
                                    ),
                                  })}
                                </div>
                              </>
                            )
                          ) : (
                            <div className="quantity-price">
                              {number_format(
                                (selectedOption
                                  ? getWhsalePrice(
                                      quantity,
                                      selectedOption.price,
                                      selectedOption
                                    )
                                  : getWhsalePrice(
                                      quantity,
                                      productDetailsState.product.price
                                    )) * quantity
                              )}
                            </div>
                          )}
                        </div>
                        <div className=" align-items-center">
                          {featuresState?.features?.includes("add-to-cart") && (
                            <button
                              className="submit-btn-invert product-detail-addCart submit-btn mx-1"
                              aria-label="Add to cart"
                              onClick={handleAddToCart}
                              disabled={
                                addToCartState.loading ||
                                quantity === "" ||
                                quantity < minQuantity ||
                                quantity > maxQuantity ||
                                (productDetailsState.product &&
                                  productDetailsState.product.has_options &&
                                  !selectedOption)
                              }
                            >
                              <i className="fa fa-shopping-cart mx-2" />{" "}
                              {t("add to cart")}
                              {addToCartState.loading && (
                                <span
                                  className={`spinner-border spinner-border-sm ${
                                    document.body.dir === "rtl"
                                      ? "mr-2"
                                      : "ml-2"
                                  }`}
                                  role="status"
                                  aria-hidden="false"
                                ></span>
                              )}
                            </button>
                          )}
                        </div>
                      </>
                    )}
                    {featuresState?.features?.includes("buy-now") && (
                      <OrderProduct
                        selectedOption={selectedOption}
                        selectedImage={selectedImage}
                        product={productDetailsState.product}
                        quantity={quantity}
                        minQuantity={minQuantity}
                        maxQuantity={maxQuantity}
                        handleFees={handleFees}
                        setQuantity={setQuantity}
                        handleDecrementQty={handleDecrementQty}
                        handleIncrementQty={handleIncrementQty}
                        handleAddToCart={handleAddToCart}
                        handleQtyChange={handleQtyChange}
                      />
                    )}

                    <div className="col-md-12 p-0">
                      <div className="product-description-tabs">
                        {/* this ul is d-none we should take each li and put it in his correct div , check the divs after this ul */}
                        <ul
                          className="nav nav-pills mb-3 justify-content-center"
                          id="product-description-tabs"
                          role="tablist"
                        >
                          <li className="nav-item" role="tab">
                            <a
                              className="nav-link active"
                              id="pills-description-tab"
                              data-toggle="pill"
                              href="#description-tab"
                              role="tab"
                              aria-controls="description-tab"
                              aria-selected="true"
                            >
                              {t("product_description")}
                            </a>
                          </li>
                          {featuresState?.features?.includes(
                            "specifications"
                          ) &&
                            !productDetailsState.loading &&
                            Object.keys(
                              productDetailsState.product.specifications ?? {}
                            ).length > 0 && (
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="pills-specifications-tab"
                                  data-toggle="pill"
                                  href="#pills-specifications"
                                  role="tab"
                                  aria-controls="pills-specifications"
                                  aria-selected="false"
                                >
                                  {t("specifications")}
                                </a>
                              </li>
                            )}
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="description-tab"
                            role="tabpanel"
                            aria-labelledby="pills-description-tab"
                          >
                            <div className="content p-0 description-fitment">
                              <p
                                className={`${
                                  arabic.test(
                                    productDetailsState.product.description
                                  )
                                    ? "text-right"
                                    : "text-left"
                                }`}
                                style={{
                                  direction: arabic.test(
                                    productDetailsState.product.description
                                  )
                                    ? "rtl"
                                    : "ltr",
                                }}
                              >
                                {parse(
                                  productDetailsState.product.description ?? ""
                                )}
                              </p>
                            </div>
                          </div>
                          {/* Specifications section */}
                          {featuresState?.features?.includes(
                            "specifications"
                          ) && (
                            <div
                              className="tab-pane fade"
                              id="pills-specifications"
                              role="tabpanel"
                              aria-labelledby="pills-specifications-tab"
                            >
                              <div className="content p-0">
                                <h4
                                  className={`${
                                    document.body.dir === "rtl"
                                      ? "text-right"
                                      : "text-left"
                                  }`}
                                >
                                  {t("specifications")}
                                </h4>
                                {!productDetailsState.loading &&
                                  Object.keys(
                                    productDetailsState.product
                                      .specifications ?? {}
                                  ).map((key, index) => (
                                    <dl
                                      className="specifications-table d-flex"
                                      key={key + "-" + index}
                                    >
                                      <dt>{key}</dt>
                                      <dd>
                                        {productDetailsState.product.specifications[key]}
                                      </dd>
                                    </dl>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Tags section */}
                    {featuresState?.features?.includes("tag") &&
                      productDetailsState?.product?.tags &&  productDetailsState.product.tags.length > 0 &&(
                      <div className="product-description-tabs text-center">
                        <div className="tab-header mb-3">
                          <h5 className="m-0">{t("Keywords")}</h5>
                        </div>
                        <div className="keywords-body">
                          {productDetailsState.product.tags.length > 0 &&
                          productDetailsState.product.tags.map((tag, index) => (
                            index + 1 === productDetailsState.product.tags.length ? (
                              <a onClick={(e) => {
                                e.preventDefault();
                                navigate("/shop?tags[]=" + tag.name);

                              }}>{tag.name}</a>
                            ) : (
                              <>
                                <a onClick={(e) => {
                                e.preventDefault();
                                navigate("/shop?tags[]=" + tag.name);

                              }}>{tag.name}</a>,{" "}
                              </>
                            )
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Reviews section */}
                    {featuresState?.features?.includes("reviews") && (
                      <Suspense
                        fallback={
                          <ImageLoading width={"100%"} height={"250px"} />
                        }
                      >
                        <RatingsSection
                          productRatingsState={productRatingsState}
                          pageClick={handlePageClick}
                        />
                      </Suspense>
                    )}

                    {/* Share buttons section */}
                    {featuresState?.features?.includes("share-buttons") && (
                      <Suspense
                        fallback={
                          <ImageLoading width={"100%"} height={"40px"} />
                        }
                      >
                        <ShareButtons selectedImage={selectedImage} />
                      </Suspense>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="col-12 product-name-price-mobile">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="product-details-product-img-wrapper">
                        <ImageLoading width={"100%"} aspectRatio={1} />
                      </div>
                      <OwlCarousel
                        className="owl-carousel product-details-slider"
                        loop={false}
                        nav
                        autoplay
                        margin={15}
                        items={4}
                        rtlClass={`${
                          document.body.dir === "rtl" ? "owl-rtl" : ""
                        }`}
                        autoWidth={false}
                        key={"product-details-slider-loader-1"}
                      >
                        {[...Array(4)].map((item, i) => (
                          <div
                            className="slider-wrapper item"
                            key={`gallery-item-loader-1-${i}`}
                          >
                            <div className="item">
                              <div className="product-details-slider-thumb">
                                <ImageLoading width={"129px"} aspectRatio={1} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </OwlCarousel>
                    </div>
                    <div className="col-md-6 mt-2">
                      <h3 className="product-details-product-name">
                        <ImageLoading width={"80%"} aspectRatio={"560/50"} />
                      </h3>
                      <hr />
                      <p className="product-details-product-description mb-0">
                        <div className="mb-2">
                          <ImageLoading width={"100%"} aspectRatio={"560/18"} />
                        </div>
                      </p>
                      <hr />
                      <div className="d-flex align-items-baseline mb-3">
                        <ImageLoading width={"150px"} aspectRatio={"150/38"} />
                      </div>
                      {featuresState?.features?.includes("reviews") &&
                        productRatingsState?.loading && (
                          <div className="total-product-rate mb-2 d-flex flex-row">
                            <div
                              className="rating_wrap"
                              style={{ width: "123px" }}
                            >
                              <div className="rating">
                                <div className="current-rate" />
                              </div>
                            </div>
                          </div>
                        )}
                      <div className="d-flex align-items-center mb-3">
                        <ImageLoading
                          width={"180px"}
                          aspectRatio={"5"}
                          marginInlineEnd="0.5rem"
                        />
                        <ImageLoading width={"180px"} aspectRatio={"5"} />
                      </div>
                      <ImageLoading width={"100%"} aspectRatio={"1"} />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="product-name-price">
                    <h3 className="product-details-product-name">
                      <ImageLoading width={"80%"} aspectRatio={"560/50"} />
                    </h3>
                    <p className="product-details-product-description my-3">
                      <div className="mb-2">
                        <ImageLoading width={"100%"} aspectRatio={"560/18"} />
                      </div>
                    </p>
                    <div className="d-flex align-items-baseline mb-3">
                      <ImageLoading width={"150px"} aspectRatio={"150/38"} />
                    </div>
                    {featuresState?.features?.includes("reviews") &&
                      productRatingsState?.loading && (
                        <div className="total-product-rate mb-2 d-flex flex-row">
                          <div
                            className="rating_wrap"
                            style={{ width: "123px" }}
                          >
                            <div className="rating">
                              <div className="current-rate" />
                            </div>
                          </div>
                        </div>
                      )}
                    <div className="product-details-product-img-wrapper">
                      <ImageLoading width={"100%"} aspectRatio={1} />
                    </div>
                    <OwlCarousel
                      className="owl-carousel product-details-slider"
                      loop={false}
                      nav
                      autoplay
                      margin={15}
                      items={4}
                      rtlClass={`${
                        document.body.dir === "rtl" ? "owl-rtl" : ""
                      }`}
                      autoWidth={false}
                      key={"product-details-slider-loader-1"}
                    >
                      {[...Array(4)].map((item, i) => (
                        <div
                          className="slider-wrapper item"
                          key={`gallery-item-loader-1-${i}`}
                        >
                          <div className="item">
                            <div className="product-details-slider-thumb">
                              <ImageLoading width={"129px"} aspectRatio={1} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>

                    <ImageLoading
                      width={"100%"}
                      marginTop={"20px"}
                      aspectRatio={"0.8"}
                    />
                    <ImageLoading
                      width={"100%"}
                      marginTop={"20px"}
                      aspectRatio={"0.8"}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          {!relatedProductsState.loading &&
            relatedProductsState.items &&
            relatedProductsState.items.length > 0 && (
              <div className="row related-products-section">
                <div className="col-md-12">
                  <div className="">
                    <h3 className="login-form-header">{t("relatedProduct")}</h3>
                  </div>
                  <div className="related-products-wrapper mt-3">
                    <div className="row">
                      <ProductsCarousel
                        className="related-products-slider"
                        componentKey="related-products-slider"
                        products={relatedProductsState}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </section>

      {(settingsState?.store_infos?.phone ||
        settingsState?.store_infos?.facebook) && (
        <Suspense fallback={<></>}>
          <CallNow />
        </Suspense>
      )}
    </>
  );
};
export default ProductPage;
