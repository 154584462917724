import React from 'react'
import ImageLoading from '../ImageLoading'

const CheckoutItemLoading = (props) => {
  return (
    <li className="checkout-products-item row align-items-center" key={props.key}>
      <div className="col-md-3 col-6 col-sm-6 mb-3">
        <div className="checkout-products-item-img">
          <ImageLoading width={"100px"} aspectRatio={'100/127.5'} />
        </div>
      </div>
      <div className="col-md-3 col-6 col-sm-6 mb-3">
        <div className="checkout-products-item-name" style={{ marginBottom: '3px' }}>
          <a href='/#'>
            <ImageLoading width={"100px"} aspectRatio={'100/20'} />
          </a>
        </div>
        <span className="checkout-products-item-price"><ImageLoading width={"100px"} aspectRatio={'100/20'} /></span>
      </div>
      <div className="col-md-3 col-6 col-sm-6 mb-3">
        <div className="d-flex w-100 align-items-center">
          <ImageLoading width={"120.27px"} aspectRatio={'120.27/63'} />
          <div className="ml-auto">
            <ImageLoading width={"58px"} aspectRatio={'58/48'} />
          </div>
        </div>
      </div>
      <div className="col-md-3 col-6 col-sm-6 mb-sm-0 mb-3 align-items-end">
        <span className="checkout-products-remove-item"><ImageLoading width={"21px"} aspectRatio={'1'} /></span>
      </div>
    </li>
  )
}

export default CheckoutItemLoading