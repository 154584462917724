import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAIL,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";
import { fetchGetCart } from "./getCartActions";

const fetchAddToCartBegin = () => ({
  type: ADD_TO_CART_REQUEST,
});

const fetchAddToCartSuccess = (message) => ({
  type: ADD_TO_CART_SUCCESS,
  payload: { message },
});

const fetchAddToCartFailure = (error) => ({
  type: ADD_TO_CART_FAIL,
  payload: { error },
});

export const fetchAddToCartAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    {
      dispatch(fetchAddToCartBegin());
      api
        .post("/api/add-to-cart", data)
        .then((res) => {
          if (res.data.success) {
            dispatch(fetchAddToCartSuccess(res.data.message));
            dispatch(fetchGetCart());
            resolve(res.data.message);
          } else {
            dispatch(fetchAddToCartFailure(res.data.message));
            reject(res.data.message);
          }
        })
        .catch((error) => {
          dispatch(fetchAddToCartFailure(error.message));
          reject(error.message);
        });
    }
  });
