import {
  FEATURED_PRODUCTS_FAIL,
  FEATURED_PRODUCTS_REQUEST,
  FEATURED_PRODUCTS_SUCCESS,
} from "../../../shared/constants";

import { api } from "../../../shared/shared";

const fetchFeaturedProductsBegin = () => ({
  type: FEATURED_PRODUCTS_REQUEST,
});

const fetchFeaturedProductsSuccess = (products) => ({
  type: FEATURED_PRODUCTS_SUCCESS,
  payload: { products },
});

const fetchFeaturedProductsFailure = (error) => ({
  type: FEATURED_PRODUCTS_FAIL,
  payload: { error },
});

const fetchFeaturedProductsAction = () => (dispatch) => {
  dispatch(fetchFeaturedProductsBegin());
  api
    .get("api/products/featured")
    .then((res) =>
      res.data.success
        ? dispatch(fetchFeaturedProductsSuccess(res.data.data))
        : dispatch(fetchFeaturedProductsFailure(res.data.message))
    )
    .catch((error) => dispatch(fetchFeaturedProductsFailure(error.message)));
};

export default fetchFeaturedProductsAction;
