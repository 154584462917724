import React, { useState } from "react";
import ImageLoading from "../ImageLoading";

const AddToCartImage = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <img
        srcSet={`${props.selectedImage}?w=180&h=230 1440w,${props.selectedImage}?w=180&h=230 1024w,${props.selectedImage}?w=180&h=230 768w,${props.selectedImage}?w=180&h=230 425w,`}
        sizes="
            (max-width: 425px) 180px,
            (max-width: 768px) 180px,
            (max-width: 1024px) 180px,
            180px"
        onLoad={() => setLoaded(true)}
        style={{ display: "none" }}
        alt={props.selectedImage}
      />
      {loaded ? (
        <img
          srcSet={`${props.selectedImage}?w=180&h=230 1440w,${props.selectedImage}?w=180&h=230 1024w,${props.selectedImage}?w=180&h=230 768w,${props.selectedImage}?w=180&h=230 425w,`}
          sizes="
            (max-width: 425px) 180px,
            (max-width: 768px) 180px,
            (max-width: 1024px) 180px,
            180px"
          alt={props.selectedImage}
        />
      ) : (
        <ImageLoading width={"180px"} height={"230px"} />
      )}
    </>
  );
};

export default AddToCartImage;
