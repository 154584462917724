import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchAddToCartAction } from "../../redux/actions/cart/addToCartActions";
import fetchProductOptionsActions from "../../redux/actions/products/productOptionsActions";
import { compareObjects, number_format } from "../../shared/shared";
import { ModalContext } from "../modal/modalContext";
import ReactPixel from "react-facebook-pixel";
import "../../css/Modal/addToCart.css";
import AddToCartImage from "./AddToCartImage";
import useWholeSale from "../../hooks/useWholesale";
import WholesaleSelect from "../products/WholesaleSelect";

const AddToCartModal = (props) => {
  const { handleModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [addedSuccess, setAddedSuccess] = useState(false);
  const [addedFailure, setAddedFailure] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [productOptions, setProductOptions] = useState(null);
  const [maxQuantity, setMaxQuantity] = useState(props.product.stock);
  const [minQuantity, setMinQuantity] = useState(props.product?.order_min_quantity);
  const [nbOptionsForm, setNbOptionsForm] = useState(0);
  const [optionsForm, setOptionsForm] = useState({});
  const [selectedImage, setSelectedImage] = useState("");

  const addToCartState = useSelector((state) => state.cart.addToCart);
  const settingsState = useSelector((state) => state.storeInfos);
  const featuresState = useSelector((state) => state.features);

  const { getWhsalePrice } = useWholeSale(props.product);

  const handleQtyChange = useCallback(
    (e) => {
      let { value, min, max } = e.target;
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));
      setQuantity(value);
    },
    [minQuantity, maxQuantity]
  );

  const handleIncrementQty = () =>
    quantity < maxQuantity && setQuantity(parseInt(quantity) + 1);
  const handleDecrementQty = () =>
    quantity > minQuantity && setQuantity(parseInt(quantity) - 1);

  const handleAddToCart = () => {
    let data = {
      productId: props.product.id,
      quantity: quantity,
      model: props.product.model,
    };
    if (selectedOption) {
      data["productId"] = selectedOption.id;
      data["model"] = selectedOption.model;
    }
    if (quantity <= maxQuantity && quantity >= minQuantity) {
      dispatch(fetchAddToCartAction(data))
        .then((res) => {
          ReactPixel.track("AddToCart", {
            content_type: "product",
            content_ids: [data["productId"]],
            contents: [
              {
                id: data["productId"],
                price: selectedOption
                  ? selectedOption.special_price ?? selectedOption.price
                  : props.product.special_price ?? props.product.price,
                quantity: data["quantity"],
                name: props.product.name,
              },
            ],
          });
          setAddedSuccess(true);
        })
        .catch((err) => setAddedFailure(true));
    }
  };

  const handleOptionsFormChangement = (event) => {
    let data = { ...optionsForm };
    data[event.target.name] = event.target.value;
    setOptionsForm(data);
    let option = productOptions.options.find((option) => {
      return compareObjects(data, option.properties);
    });
    if (option) {
      setSelectedOption(option);
      setMaxQuantity(option.stock);
      setMinQuantity(option.order_min_quantity);
    } else {
      setSelectedOption(null);
      setMaxQuantity(0);
    }
  };

  const handleCloseModal = () => handleModal();

  useEffect(() => {
    if (props.product.has_options) {
      dispatch(fetchProductOptionsActions(props.product.id)).then((res) => {
        setProductOptions(res);
        if (Object.keys(res.available_options ?? {}).length > 0) {
          let data = {};
          let nb = 0;
          Object.keys(res.available_options ?? {}).map((option) => {
            data[option] = null;
            nb += 1;
          });
          setOptionsForm(data);
          setNbOptionsForm(nb);
        }
      });
    }
  }, []);

  useEffect(() => {
    selectedOption
      ? setSelectedImage(selectedOption.image)
      : setSelectedImage(props.product.image);
  }, [selectedOption]);

  return (
    <div
      className="modal fade show"
      id="add-to-cart-modal"
      style={{ display: "block", paddingright: "15px" }}
    >
      <div
        className={`modal-dialog ${!addedSuccess && "add-to-cart-model"}`}
        role="document"
      >
        <div className="modal-content">
          <button
            type="button"
            className="modal-dismiss"
            data-dismiss="modal"
            aria-label="Close modal"
            disabled={addToCartState.loading}
            onClick={handleCloseModal}
          >
            <span>
              <i className="fa fa-times" />
            </span>
          </button>
          {addedSuccess ? (
            <div className="row">
              <div className="col-md-12 justify-content-center">
                <h3 className="text-center login-form-header">
                  {t("product_added_successfully")}
                </h3>
                <div className="row justify-content-center m-0">
                  <div className="order-success-wrapper col-md-12 text-center">
                    <h3>
                      {t(
                        "congratulations_your_product_has_been_successfully_added_to_cart"
                      )}
                    </h3>
                    <div className="mb-3">
                      <i className="fa fa-check" />
                    </div>
                    <button
                      className="remove-all-btn mx-2 my-2 cancel-btn"
                      aria-label="Close modal"
                      onClick={handleCloseModal}
                    >
                      {t("continue_shopping")}
                    </button>
                    <Link to="/checkout" className="mx-2 my-2">
                      <button
                        className="submit-btn"
                        onClick={handleCloseModal}
                        aria-label="Checkout"
                      >
                        {document.body.dir === "ltr" ? (
                          <>
                            <i className="fa fa-chevron-right ml-2"></i>
                            {t("checkout")}
                          </>
                        ) : (
                          <>
                            {t("checkout")}
                            <i className="fa fa-chevron-left mr-2"></i>
                          </>
                        )}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-body">
              <div>
                <div className="add-to-cart-modal-product-details row">
                  <div className="col-md-5">
                    <div className="product-img-wrapper">
                      <AddToCartImage selectedImage={selectedImage} />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h3 className="modal-product-name">{props.product.name}</h3>
                    {selectedOption ? (
                      selectedOption.special_price ? (
                        <div
                          className={`d-flex align-items-baseline ${
                            document.body.dir === "rtl" && "text-right"
                          }`}
                        >
                          {settingsState?.store_infos?.currency ? (
                            settingsState.store_infos.currency !== "DZD" ? (
                              <>
                                <span className="modal-product-price mx-1">
                                  {t("currency", {
                                    price: number_format(
                                      selectedOption.special_price
                                    ),
                                    currency:
                                      settingsState.store_infos.currency,
                                  })}
                                </span>
                                <span className="modal-product-old-price">
                                  {t("currency", {
                                    price: number_format(selectedOption.price),
                                    currency:
                                      settingsState.store_infos.currency,
                                  })}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="modal-product-price mx-1">
                                  {t("DA", {
                                    price: number_format(
                                      selectedOption.special_price
                                    ),
                                  })}
                                </span>
                                <span className="modal-product-old-price">
                                  {t("DA", {
                                    price: number_format(selectedOption.price),
                                  })}
                                </span>
                              </>
                            )
                          ) : (
                            <>
                              <span className="modal-product-price mx-1">
                                {number_format(selectedOption.special_price)}
                              </span>
                              <span className="modal-product-old-price">
                                {number_format(selectedOption.price)}
                              </span>
                            </>
                          )}
                        </div>
                      ) : (
                        <div
                          className={`d-flex align-items-baseline ${
                            document.body.dir === "rtl" && "text-right"
                          }`}
                        >
                          {settingsState?.store_infos?.currency ? (
                            settingsState.store_infos.currency !== "DZD" ? (
                              <span className="modal-product-price ">
                                {t("currency", {
                                  price: number_format(
                                    getWhsalePrice(
                                      quantity,
                                      selectedOption.price,
                                      selectedOption
                                    )
                                  ),
                                  currency: settingsState.store_infos.currency,
                                })}
                              </span>
                            ) : (
                              <span className="modal-product-price ">
                                {t("DA", {
                                  price: number_format(
                                    getWhsalePrice(
                                      quantity,
                                      selectedOption.price,
                                      selectedOption
                                    )
                                  ),
                                })}
                              </span>
                            )
                          ) : (
                            <span className="modal-product-price ">
                              {number_format(
                                getWhsalePrice(
                                  quantity,
                                  selectedOption.price,
                                  selectedOption
                                )
                              )}
                            </span>
                          )}
                        </div>
                      )
                    ) : props.product.special_price ? (
                      <div
                        className={`d-flex align-items-baseline ${
                          document.body.dir === "rtl" && "text-right"
                        }`}
                      >
                        {settingsState?.store_infos?.currency ? (
                          settingsState.store_infos.currency !== "DZD" ? (
                            <>
                              <span className="modal-product-price mx-1">
                                {t("currency", {
                                  price: number_format(
                                    props.product.special_price
                                  ),
                                  currency: settingsState.store_infos.currency,
                                })}
                              </span>
                              <span className="modal-product-old-price">
                                {t("currency", {
                                  price: number_format(props.product.price),
                                  currency: settingsState.store_infos.currency,
                                })}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="modal-product-price mx-1">
                                {t("DA", {
                                  price: number_format(
                                    props.product.special_price
                                  ),
                                })}
                              </span>
                              <span className="modal-product-old-price">
                                {t("DA", {
                                  price: number_format(props.product.price),
                                })}
                              </span>
                            </>
                          )
                        ) : (
                          <>
                            <span className="modal-product-price mr-1">
                              {number_format(props.product.special_price)}
                            </span>
                            <span className="modal-product-old-price">
                              {number_format(props.product.price)}
                            </span>
                          </>
                        )}
                      </div>
                    ) : (
                      <div
                        className={`d-flex align-items-baseline ${
                          document.body.dir === "rtl" && "text-right"
                        }`}
                      >
                        {settingsState?.store_infos?.currency ? (
                          settingsState.store_infos.currency !== "DZD" ? (
                            <span className="modal-product-price ">
                              {t("currency", {
                                price: number_format(
                                  getWhsalePrice(quantity, props.product.price)
                                ),
                                currency: settingsState.store_infos.currency,
                              })}
                            </span>
                          ) : (
                            <span className="modal-product-price ">
                              {t("DA", {
                                price: number_format(
                                  getWhsalePrice(quantity, props.product.price)
                                ),
                              })}
                            </span>
                          )
                        ) : (
                          <span className="modal-product-price ">
                            {number_format(
                              getWhsalePrice(quantity, props.product.price)
                            )}
                          </span>
                        )}
                      </div>
                    )}
                    {nbOptionsForm > 0 && (
                      <ul className="list-unstyled mt-4 mb-0">
                        {Object.keys(optionsForm ?? {}).map((option, index) => (
                          <li className="mb-2" key={"option-" + index}>
                            <div className="custom-select-wrapper">
                              <label>{option}:</label>
                              <select
                                className="custom-select"
                                onChange={(event) =>
                                  handleOptionsFormChangement(event)
                                }
                                name={option}
                              >
                                <option key={option + "00"} value={""}>
                                  {t("selectAProperty")}
                                </option>
                                {productOptions.available_options[option].map(
                                  (value) => (
                                    <option key={option + value} value={value}>
                                      {value}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                    {featuresState?.features?.includes("wholesale") &&
                      (props.product?.wholesale?.length > 0 ||
                        selectedOption?.wholesale?.length > 0) && (
                        <WholesaleSelect
                          wh_offers={
                            selectedOption
                              ? selectedOption?.wholesale
                              : props.product?.wholesale
                          }
                        />
                      )}
                    <div className="d-flex align-items-center">
                      <label
                        style={{ minWidth: 100, marginRight: 10 }}
                        className={`${
                          document.body.dir === "rtl" && "text-right"
                        }`}
                      >
                        {t("quantity")}:
                      </label>
                      <div className="quantity-selector">
                        <div className="d-inline-block">
                          <button
                            type="button"
                            aria-label="Decrement quantity"
                            onClick={handleDecrementQty}
                          >
                            <i className="fa fa-minus" />
                          </button>
                        </div>
                        <input
                          type="number"
                          min={minQuantity}
                          max={maxQuantity}
                          value={quantity}
                          onChange={handleQtyChange}
                        />
                        <div className="d-inline-block">
                          <button
                            type="button"
                            className="plus-btn"
                            aria-label="Increment quantity"
                            onClick={handleIncrementQty}
                          >
                            <i className="fa fa-plus" />
                          </button>
                        </div>
                      </div>
                      <p
                        className={`font-weight-bold p-2 mt-2 ${
                          document.body.dir === "rtl" && "text-right"
                        }`}
                      >
                        {t("min quantity", {
                          qty: selectedOption
                            ? selectedOption?.order_min_quantity
                            : props?.product?.order_min_quantity,
                        })}
                      </p>
                    </div>
                    <div
                      className={`custom-input ${
                        document.body.dir === "rtl" ? "text-right" : "text-left"
                      }`}
                    >
                      {(((quantity === "" ||
                        quantity < minQuantity ||
                        quantity > maxQuantity) &&
                        props.product.has_options &&
                        selectedOption) ||
                        ((quantity === "" ||
                          quantity < minQuantity ||
                          quantity > maxQuantity) &&
                          !props.product.has_options)) &&
                        maxQuantity !== 0 && (
                          <span className="custom-input-error-message">
                            {t("validations.between", {
                              attribute: t("quantity"),
                              min: minQuantity,
                              max: maxQuantity,
                            })}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="add-to-cart-total">
                  <h5>{t("total")}</h5>
                  <p>
                    {t("total_products")}:{" "}
                    <span>
                      {selectedOption ? (
                        <span>
                          {settingsState?.store_infos?.currency
                            ? settingsState.store_infos.currency !== "DZD"
                              ? t("currency", {
                                  price: number_format(
                                    (selectedOption.special_price ??
                                      getWhsalePrice(
                                        quantity,
                                        selectedOption.price,
                                        selectedOption
                                      )) * quantity
                                  ),
                                  currency: settingsState.store_infos.currency,
                                })
                              : t("DA", {
                                  price: number_format(
                                    (selectedOption.special_price ??
                                      getWhsalePrice(
                                        quantity,
                                        selectedOption.price,
                                        selectedOption
                                      )) * quantity
                                  ),
                                })
                            : number_format(
                                (selectedOption.special_price ??
                                  getWhsalePrice(
                                    quantity,
                                    selectedOption.price,
                                    selectedOption
                                  )) * quantity
                              )}
                        </span>
                      ) : (
                        <span>
                          {settingsState?.store_infos?.currency
                            ? settingsState.store_infos.currency !== "DZD"
                              ? t("currency", {
                                  price: number_format(
                                    (props.product.special_price ??
                                      getWhsalePrice(
                                        quantity,
                                        props.product.price
                                      )) * quantity
                                  ),
                                  currency: settingsState.store_infos.currency,
                                })
                              : t("DA", {
                                  price: number_format(
                                    (props.product.special_price ??
                                      getWhsalePrice(
                                        quantity,
                                        props.product.price
                                      )) * quantity
                                  ),
                                })
                            : number_format(
                                (props.product.special_price ??
                                  getWhsalePrice(
                                    quantity,
                                    props.product.price
                                  )) * quantity
                              )}
                        </span>
                      )}
                    </span>
                  </p>
                  {addedFailure && (
                    <div className="alert alert-danger" role="alert">
                      {addToCartState.message}
                    </div>
                  )}
                </div>
                <div className="mt-3 w-100">
                  <button
                    className="cancel-btn"
                    aria-label="Close modal"
                    disabled={addToCartState.loading}
                    onClick={handleCloseModal}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className="submit-btn float-right"
                    aria-label="Add to cart"
                    onClick={handleAddToCart}
                    disabled={
                      addToCartState.loading ||
                      quantity === "" ||
                      quantity < minQuantity ||
                      quantity > maxQuantity ||
                      (props.product.has_options && !selectedOption)
                    }
                  >
                    {t("add to cart")}
                    {addToCartState.loading && (
                      <span
                        className={`spinner-border spinner-border-sm ${
                          document.body.dir === "rtl" ? "mr-2" : "ml-2"
                        }`}
                        role="status"
                        aria-hidden="false"
                      ></span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddToCartModal;
