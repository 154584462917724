import { api } from "../../../shared/shared";
const fetchProductOptionsActions = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    api
      .get("api/products/" + id + "/options")
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((error) => {
        reject(error.message);
      });
  });
export default fetchProductOptionsActions;
