import {
  REMOVE_ITEM_FROM_CART_REQUEST,
  REMOVE_ITEM_FROM_CART_SUCCESS,
  REMOVE_ITEM_FROM_CART_FAIL,
} from "../../../shared/constants";

import { api } from "../../../shared/shared";

import { fetchGetCart } from "./getCartActions";

const fetchRemoveFromCartBegin = () => ({
  type: REMOVE_ITEM_FROM_CART_REQUEST,
});

const fetchRemoveFromCartSuccess = (message) => ({
  type: REMOVE_ITEM_FROM_CART_SUCCESS,
  payload: { message },
});

const fetchRemoveFromCartFailure = (error) => ({
  type: REMOVE_ITEM_FROM_CART_FAIL,
  payload: { error },
});

export const fetchRemoveItemFromCart = (rowId) => (dispatch) => {
  dispatch(fetchRemoveFromCartBegin());
  api
    .post("/api/remove-item-from-cart/", { rowId })
    .then((res) => {
      if (res.data.success) {
        dispatch(fetchRemoveFromCartSuccess(res.data.message));
        dispatch(fetchGetCart());
      } else dispatch(fetchRemoveFromCartFailure(res.data.message));
    })
    .catch((error) => {
      dispatch(fetchRemoveFromCartFailure(error.message));
    });
};
