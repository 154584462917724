import {
  UPDATE_QUANTITY_ITEM_CART_REQUEST,
  UPDATE_QUANTITY_ITEM_CART_SUCCESS,
  UPDATE_QUANTITY_ITEM_CART_FAIL,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";

import { fetchGetCart } from "./getCartActions";

const fetchUpdateQuantityCartItemBegin = () => ({
  type: UPDATE_QUANTITY_ITEM_CART_REQUEST,
});

const fetchUpdateQuantityCartItemSuccess = (message) => ({
  type: UPDATE_QUANTITY_ITEM_CART_SUCCESS,
  payload: { message },
});

const fetchUpdateQuantityCartItemFailure = (error) => ({
  type: UPDATE_QUANTITY_ITEM_CART_FAIL,
  payload: { error },
});

export const fetchUpdateQuantityCartItem = (rowId, quantity) => (dispatch) => {
  dispatch(fetchUpdateQuantityCartItemBegin());
  api
    .post("/api/update-quantity-item-cart", {
      rowId: rowId,
      quantity: quantity,
    })
    .then((res) => {
      if (res.data.success) {
        dispatch(fetchUpdateQuantityCartItemSuccess(res.data.message));
        dispatch(fetchGetCart());
      } else dispatch(fetchUpdateQuantityCartItemFailure(res.data.message));
    })
    .catch((error) =>
      dispatch(fetchUpdateQuantityCartItemFailure(error.message))
    );
};
